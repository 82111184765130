import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/kafka';
import { fetchKafkaMetadata, fetchKafkaTopicOffsets } from '@api/kafka';
import KafkaMeta from '@models/KafkaMeta';
import KafkaOffset from '@models/KafkaOffset';

export function* fetchKafkaMetadataSaga() {
  try {
    const data: KafkaMeta = yield call(fetchKafkaMetadata);

    yield put(types.fetchKafkaMetadata.success(data));
  } catch (error) {
    yield put(types.fetchKafkaMetadata.failure());
  }
}

export function* fetchKafkaTopicOffsetsSaga(
  action: ActionType<typeof types.fetchKafkaTopicOffsets.request>
) {
  const { group } = action.payload;

  try {
    const data: KafkaOffset[] = yield call(fetchKafkaTopicOffsets, group);

    yield put(types.fetchKafkaTopicOffsets.success(data));
  } catch (error) {
    yield put(types.fetchKafkaTopicOffsets.failure());
  }
}
