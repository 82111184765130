import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/contactGroups';
import {
  createContactGroup,
  deleteContactGroup,
  fetchContactGroups,
  updateContactGroup,
} from '@api/contactGroups';
import ContactGroup from '@models/ContactGroup';

export function* createContactGroupSaga(
  action: ActionType<typeof types.createContactGroup.request>
) {
  const { name, contacts } = action.payload;
  try {
    const contactGroup: ContactGroup = yield call(
      createContactGroup,
      name,
      contacts
    );

    yield put(types.createContactGroup.success(contactGroup));
  } catch (error) {
    yield put(types.createContactGroup.failure());
  }
}

export function* deleteContactGroupSaga(
  action: ActionType<typeof types.deleteContactGroup.request>
) {
  const { id } = action.payload;
  try {
    yield call(deleteContactGroup, id);

    yield put(types.deleteContactGroup.success({ id }));
  } catch (error) {
    yield put(types.deleteContactGroup.failure({ id }));
  }
}

export function* fetchContactGroupsSaga() {
  try {
    const contactGroups: ContactGroup[] = yield call(fetchContactGroups);

    yield put(types.fetchContactGroups.success(contactGroups));
  } catch (error) {
    yield put(types.fetchContactGroups.failure());
  }
}

export function* updateContactGroupSaga(
  action: ActionType<typeof types.updateContactGroup.request>
) {
  const { id, name, contacts } = action.payload;
  try {
    const contactGroup: ContactGroup = yield call(
      updateContactGroup,
      id,
      name,
      contacts
    );

    yield put(types.updateContactGroup.success(contactGroup));
  } catch (error) {
    yield put(types.updateContactGroup.failure());
  }
}
