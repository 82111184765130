import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { Component } from 'react';

import Notification from '@models/Notification';
import NotificationsList from './NotificationsList';

interface IProps {
  dismissNotifications: (ids: string[]) => void;
  dismissLoading: boolean;
  fetchNotifications: () => void;
  loading: boolean;
  notifications: Notification[];
}

interface IState {
  anchorElement?: HTMLElement;
  open: boolean;
}

class NotificationsMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorElement: undefined,
      open: false,
    };

    this.handleDismissAllClick = this.handleDismissAllClick.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  public componentDidMount() {
    const { fetchNotifications } = this.props;

    fetchNotifications();
  }

  public handleDismissAllClick() {
    const { dismissNotifications, notifications } = this.props;

    dismissNotifications(
      notifications.filter((a) => a.dismissed === false).map((a) => a.id)
    );
  }

  public toggleMenu(e: any) {
    const { open } = this.state;

    this.setState({
      anchorElement: !open ? e.currentTarget : undefined,
      open: !open,
    });
  }

  public render() {
    const { dismissLoading, loading, notifications } = this.props;
    const { open, anchorElement } = this.state;

    return (
      <>
        {loading === false && (
          <IconButton
            color="inherit"
            aria-label="Notifications"
            onClick={this.toggleMenu}
          >
            <Badge
              badgeContent={
                notifications.filter((n) => n.dismissed !== true).length
              }
              max={999}
              color="secondary"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        )}
        <Popover
          className="notifications-menu"
          open={open}
          anchorEl={anchorElement}
          onClose={this.toggleMenu}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <Box
            p={2}
            style={{
              minWidth: '360px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body1">Notifications</Typography>

            {notifications.length > 1 && (
              <Button
                aria-label="Dismiss all"
                onClick={this.handleDismissAllClick}
                disabled={dismissLoading}
                style={{ textTransform: 'none' }}
              >
                {dismissLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <VisibilityOffIcon />
                )}

                <span style={{ marginLeft: '5px' }}>Dismiss all</span>
              </Button>
            )}
          </Box>

          <Divider />

          <NotificationsList />
        </Popover>
      </>
    );
  }
}

export default NotificationsMenu;
