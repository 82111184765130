import KafkaMeta from '@models/KafkaMeta';
import KafkaOffset from '@models/KafkaOffset';
import axios from './axios';

export async function fetchKafkaMetadata(): Promise<KafkaMeta> {
  const { data } = await axios.get('/kafka/metadata');

  return data;
}

export async function fetchKafkaTopicOffsets(
  group: string
): Promise<KafkaOffset[]> {
  const { data } = await axios.get(`/kafka/topicoffsets/group/${group}`);

  return data;
}
