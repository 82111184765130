import { createAction, createAsyncAction } from 'typesafe-actions';

import Service from '@models/Service';
import ServiceInfo from '@models/ServiceInfo';
import ServiceStatus from '@models/ServiceStatus';

enum Types {
  FETCH_SERVICESINFO = '@@system/FETCH_SERVICESINFO',
  FETCH_SERVICESINFO_SUCCEEDED = '@@system/FETCH_SERVICESINFO_SUCCEEDED',
  FETCH_SERVICESINFO_FAILED = '@@system/FETCH_SERVICESINFO_FAILED',

  FETCH_SERVICESSTATUS = '@@system/FETCH_SERVICESSTATUS',
  FETCH_SERVICESSTATUS_SUCCEEDED = '@@system/FETCH_SERVICESSTATUS_SUCCEEDED',
  FETCH_SERVICESSTATUS_FAILED = '@@system/FETCH_SERVICESSTATUS_FAILED',

  FETCH_SYSTEM_INFRASTRUCTURE = '@@system/FETCH_SYSTEM_INFRASTRUCTURE',
  FETCH_SYSTEM_INFRASTRUCTURE_SUCCEEDED = '@@system/FETCH_SYSTEM_INFRASTRUCTURE_SUCCEEDED',
  FETCH_SYSTEM_INFRASTRUCTURE_FAILED = '@@system/FETCH_SYSTEM_INFRASTRUCTURE_FAILED',

  CREATE_SYSTEM_SERVICE = '@@system/CREATE_SYSTEM_SERVICE',
  CREATE_SYSTEM_SERVICE_SUCCEEDED = '@@system/CREATE_SYSTEM_SERVICE_SUCCEEDED',
  CREATE_SYSTEM_SERVICE_FAILED = '@@system/CREATE_SYSTEM_SERVICE_FAILED',

  UPDATE_SYSTEM_SERVICE = '@@system/UPDATE_SYSTEM_SERVICE',
  UPDATE_SYSTEM_SERVICE_SUCCEEDED = '@@system/UPDATE_SYSTEM_SERVICE_SUCCEEDED',
  UPDATE_SYSTEM_SERVICE_FAILED = '@@system/UPDATE_SYSTEM_SERVICE_FAILED',

  DELETE_SYSTEM_SERVICE = '@@system/DELETE_SYSTEM_SERVICE',
  DELETE_SYSTEM_SERVICE_SUCCEEDED = '@@system/DELETE_SYSTEM_SERVICE_SUCCEEDED',
  DELETE_SYSTEM_SERVICE_FAILED = '@@system/DELETE_SYSTEM_SERVICE_FAILED',

  OPENFORM = '@@system/OPENFORM',
  CLOSEFORM = '@@system/CLOSEFORM',
}

export const fetchServicesInfo = createAsyncAction(
  Types.FETCH_SERVICESINFO,
  Types.FETCH_SERVICESINFO_SUCCEEDED,
  Types.FETCH_SERVICESINFO_FAILED
)<void, ServiceInfo[], void>();

export const fetchServicesStatus = createAsyncAction(
  Types.FETCH_SERVICESSTATUS,
  Types.FETCH_SERVICESSTATUS_SUCCEEDED,
  Types.FETCH_SERVICESSTATUS_FAILED
)<void, ServiceStatus[], void>();

export interface ICreateService {
  serviceName: string;
  type: string;
  environment: string;
  instances: number;
  vcpus: number;
}
export interface IUpdateService extends ICreateService {
  id: string;
}
export interface IDeleteService {
  id: string;
}

export const fetchInfrastructure = createAsyncAction(
  Types.FETCH_SYSTEM_INFRASTRUCTURE,
  Types.FETCH_SYSTEM_INFRASTRUCTURE_SUCCEEDED,
  Types.FETCH_SYSTEM_INFRASTRUCTURE_FAILED
)<void, Service[], void>();

export const createService = createAsyncAction(
  Types.CREATE_SYSTEM_SERVICE,
  Types.CREATE_SYSTEM_SERVICE_SUCCEEDED,
  Types.CREATE_SYSTEM_SERVICE_FAILED
)<ICreateService, Service, void>();

export const updateService = createAsyncAction(
  Types.UPDATE_SYSTEM_SERVICE,
  Types.UPDATE_SYSTEM_SERVICE_SUCCEEDED,
  Types.UPDATE_SYSTEM_SERVICE_FAILED
)<IUpdateService, Service, void>();

export const deleteService = createAsyncAction(
  Types.DELETE_SYSTEM_SERVICE,
  Types.DELETE_SYSTEM_SERVICE_SUCCEEDED,
  Types.DELETE_SYSTEM_SERVICE_FAILED
)<IDeleteService, IDeleteService, void>();

export const openServiceForm = createAction(
  Types.OPENFORM,
  (data?: Service) => data
)();
export const closeServiceForm = createAction(Types.CLOSEFORM)();
