import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/alerts';
import {
  activateAlert,
  createAlert,
  deactivateAlert,
  deleteAlert,
  fetchAlerts,
  resetAlert,
  updateAlert,
} from '@api/alerts';
import Alert from '@models/Alert';

export function* createAlertSaga(
  action: ActionType<typeof types.createAlert.request>
) {
  try {
    const data: Alert = yield call(createAlert, action.payload);

    yield put(types.createAlert.success(data));
  } catch (error) {
    yield put(types.createAlert.failure());
  }
}

export function* activateAlertSaga(
  action: ActionType<typeof types.activateAlert.request>
) {
  try {
    yield call(activateAlert, action.payload);

    yield put(types.activateAlert.success({ id: action.payload }));
  } catch (error) {
    yield put(types.activateAlert.failure());
  }
}

export function* deactivateAlertSaga(
  action: ActionType<typeof types.deactivateAlert.request>
) {
  try {
    yield call(deactivateAlert, action.payload);

    yield put(types.deactivateAlert.success({ id: action.payload }));
  } catch (error) {
    yield put(types.deactivateAlert.failure());
  }
}

export function* deleteAlertSaga(
  action: ActionType<typeof types.deleteAlert.request>
) {
  const { id } = action.payload;
  try {
    yield call(deleteAlert, id);

    yield put(types.deleteAlert.success({ id }));
  } catch (error) {
    yield put(types.deleteAlert.failure({ id }));
  }
}

export function* fetchAlertsSaga() {
  try {
    const alerts: Alert[] = yield call(fetchAlerts);

    yield put(types.fetchAlerts.success(alerts));
  } catch (error) {
    yield put(types.fetchAlerts.failure());
  }
}

export function* resetAlertSaga(
  action: ActionType<typeof types.updateAlert.request>
) {
  try {
    const data: Alert = yield call(resetAlert, action.payload);

    yield put(types.resetAlert.success(data));
  } catch (error) {
    yield put(types.resetAlert.failure());
  }
}

export function* updateAlertSaga(
  action: ActionType<typeof types.updateAlert.request>
) {
  try {
    const data: Alert = yield call(updateAlert, action.payload);

    yield put(types.updateAlert.success(data));
  } catch (error) {
    yield put(types.updateAlert.failure());
  }
}
