import { createAsyncAction } from 'typesafe-actions';

import Notification from '@models/Notification';

enum Types {
  DISMISS_NOTIFICATIONS = '@@notifications/DISMISS_NOTIFICATIONS',
  DISMISS_NOTIFICATIONS_SUCCEEDED = '@@notifications/DISMISS_NOTIFICATIONS_SUCCEEDED',
  DISMISS_NOTIFICATIONS_FAILED = '@@notifications/DISMISS_NOTIFICATIONS_FAILED',

  FETCH_NOTIFICATIONS = '@@notifications/FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_SUCCEEDED = '@@notifications/FETCH_NOTIFICATIONS_SUCCEEDED',
  FETCH_NOTIFICATIONS_FAILED = '@@notifications/FETCH_NOTIFICATIONS_FAILED',
}

export const dismissNotifications = createAsyncAction(
  Types.DISMISS_NOTIFICATIONS,
  Types.DISMISS_NOTIFICATIONS_SUCCEEDED,
  Types.DISMISS_NOTIFICATIONS_FAILED
)<string[], string[], void>();

export const fetchNotifications = createAsyncAction(
  Types.FETCH_NOTIFICATIONS,
  Types.FETCH_NOTIFICATIONS_SUCCEEDED,
  Types.FETCH_NOTIFICATIONS_FAILED
)<void, Notification[], void>();
