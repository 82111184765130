import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from '@actions/index';
import {
  activateAccountSaga,
  changeAccountAdminSaga,
  fetchAccountsSaga,
  updateAccountOrganisationsSaga,
} from './accounts';
import {
  activateAlertSaga,
  createAlertSaga,
  deactivateAlertSaga,
  deleteAlertSaga,
  fetchAlertsSaga,
  resetAlertSaga,
  updateAlertSaga,
} from './alerts';
import { fetchAccountSaga, logoutSaga } from './authentication';
import {
  createContactGroupSaga,
  deleteContactGroupSaga,
  fetchContactGroupsSaga,
  updateContactGroupSaga,
} from './contactGroups';
import { fetchDataDumpSaga } from './data';
import {
  fetchGatewaysHardwareStatusSaga,
  fetchGatewaysSaga,
  fetchGatewaysSessionsStatusSaga,
  fetchSensorGroupsSaga,
} from './gateways';
import {
  activateOrganisationSaga,
  createOrganisationSaga,
  fetchOrganisationsSaga,
  updateOrganisationSaga,
} from './organisations';
import {
  fetchNotificationsSaga,
  dismissNotificationsSaga,
} from './notifications';
import { fetchKafkaMetadataSaga, fetchKafkaTopicOffsetsSaga } from './kafka';
import {
  createServiceSaga,
  deleteServiceSaga,
  fetchInfrastructureSaga,
  fetchServicesInfoSaga,
  fetchServicesStatusSaga,
  updateServiceSaga,
} from './system';
import { requestLoadTestSaga } from './tests';
import {
  fetchTicketsSaga,
  archiveTicketSaga,
  createTicketCommentSaga,
  deleteTicketCommentSaga,
} from './tickets';

export default function* saga() {
  yield takeLatest(getType(actions.createAlert.request), createAlertSaga);
  yield takeLatest(getType(actions.activateAlert.request), activateAlertSaga);
  yield takeLatest(getType(actions.deleteAlert.request), deleteAlertSaga);
  yield takeLatest(
    getType(actions.deactivateAlert.request),
    deactivateAlertSaga
  );
  yield takeLatest(getType(actions.fetchAlerts.request), fetchAlertsSaga);
  yield takeLatest(getType(actions.fetchAccount.request), fetchAccountSaga);
  yield takeLatest(
    getType(actions.activateAccount.request),
    activateAccountSaga
  );
  yield takeLatest(
    getType(actions.changeAccountAdmin.request),
    changeAccountAdminSaga
  );
  yield takeLatest(getType(actions.fetchAccounts.request), fetchAccountsSaga);
  yield takeLatest(
    getType(actions.updateAccountOrganisations.request),
    updateAccountOrganisationsSaga
  );
  yield takeLatest(getType(actions.logout.request), logoutSaga);
  yield takeLatest(getType(actions.resetAlert.request), resetAlertSaga);
  yield takeLatest(getType(actions.updateAlert.request), updateAlertSaga);
  yield takeLatest(
    getType(actions.createContactGroup.request),
    createContactGroupSaga
  );
  yield takeLatest(
    getType(actions.deleteContactGroup.request),
    deleteContactGroupSaga
  );
  yield takeLatest(
    getType(actions.updateContactGroup.request),
    updateContactGroupSaga
  );
  yield takeLatest(
    getType(actions.fetchContactGroups.request),
    fetchContactGroupsSaga
  );
  yield takeLatest(
    getType(actions.fetchKafkaMetadata.request),
    fetchKafkaMetadataSaga
  );
  yield takeLatest(
    getType(actions.fetchKafkaTopicOffsets.request),
    fetchKafkaTopicOffsetsSaga
  );
  yield takeLatest(getType(actions.createService.request), createServiceSaga);
  yield takeLatest(getType(actions.deleteService.request), deleteServiceSaga);
  yield takeLatest(
    getType(actions.fetchInfrastructure.request),
    fetchInfrastructureSaga
  );
  yield takeLatest(
    getType(actions.fetchServicesInfo.request),
    fetchServicesInfoSaga
  );
  yield takeLatest(
    getType(actions.fetchServicesStatus.request),
    fetchServicesStatusSaga
  );
  yield takeLatest(getType(actions.updateService.request), updateServiceSaga);
  yield takeLatest(
    getType(actions.requestLoadTest.request),
    requestLoadTestSaga
  );
  yield takeLatest(getType(actions.fetchTickets.request), fetchTicketsSaga);
  yield takeLatest(getType(actions.archiveTicket.request), archiveTicketSaga);
  yield takeLatest(
    getType(actions.createTicketComment.request),
    createTicketCommentSaga
  );
  yield takeLatest(
    getType(actions.deleteTicketComment.request),
    deleteTicketCommentSaga
  );
  yield takeLatest(getType(actions.fetchGateways.request), fetchGatewaysSaga);
  yield takeLatest(
    getType(actions.fetchGatewaysHardwareStatus.request),
    fetchGatewaysHardwareStatusSaga
  );
  yield takeLatest(
    getType(actions.fetchGatewaysSessionsStatus.request),
    fetchGatewaysSessionsStatusSaga
  );
  yield takeLatest(
    getType(actions.fetchSensorGroups.request),
    fetchSensorGroupsSaga
  );
  yield takeLatest(getType(actions.fetchDataDump.request), fetchDataDumpSaga);
  yield takeLatest(
    getType(actions.activateOrganisation.request),
    activateOrganisationSaga
  );
  yield takeLatest(
    getType(actions.createOrganisation.request),
    createOrganisationSaga
  );
  yield takeLatest(
    getType(actions.fetchOrganisations.request),
    fetchOrganisationsSaga
  );
  yield takeLatest(
    getType(actions.updateOrganisation.request),
    updateOrganisationSaga
  );
  yield takeLatest(
    getType(actions.fetchNotifications.request),
    fetchNotificationsSaga
  );
  yield takeLatest(
    getType(actions.dismissNotifications.request),
    dismissNotificationsSaga
  );
}
