import { createAsyncAction } from 'typesafe-actions';

import Gateway from '@models/Gateway';
import HardwareStatus from '@models/HardwareStatus';
import SensorGroup from '@models/SensorGroup';
import SessionStatus from '@models/SessionStatus';

enum Types {
  FETCH_GATEWAYS = '@@gateways/FETCH_GATEWAYS',
  FETCH_GATEWAYS_SUCCEEDED = '@@gateways/FETCH_GATEWAYS_SUCCEEDED',
  FETCH_GATEWAYS_FAILED = '@@gateways/FETCH_GATEWAYS_FAILED',

  FETCH_GATEWAYS_HARDWARESTATUS = '@@gateways/FETCH_GATEWAYS_HARDWARESTATUS',
  FETCH_GATEWAYS_HARDWARESTATUS_SUCCEEDED = '@@gateways/FETCH_GATEWAYS_HARDWARESTATUS_SUCCEEDED',
  FETCH_GATEWAYS_HARDWARESTATUS_FAILED = '@@gateways/FETCH_GATEWAYS_HARDWARESTATUS_FAILED',

  FETCH_GATEWAYS_SESSIONSSTATUS = '@@gateways/FETCH_GATEWAYS_SESSIONSSTATUS',
  FETCH_GATEWAYS_SESSIONSSTATUS_SUCCEEDED = '@@gateways/FETCH_GATEWAYS_SESSIONSSTATUS_SUCCEEDED',
  FETCH_GATEWAYS_SESSIONSSTATUS_FAILED = '@@gateways/FETCH_GATEWAYS_SESSIONSSTATUS_FAILED',

  FETCH_SENSORGROUPS = '@@gateways/FETCH_SENSORGROUPS',
  FETCH_SENSORGROUPS_SUCCEEDED = '@@gateways/FETCH_SENSORGROUPS_SUCCEEDED',
  FETCH_SENSORGROUPS_FAILED = '@@gateways/FETCH_SENSORGROUPS_FAILED',
}

export const fetchGateways = createAsyncAction(
  Types.FETCH_GATEWAYS,
  Types.FETCH_GATEWAYS_SUCCEEDED,
  Types.FETCH_GATEWAYS_FAILED
)<void, Gateway[], void>();

export const fetchGatewaysHardwareStatus = createAsyncAction(
  Types.FETCH_GATEWAYS_HARDWARESTATUS,
  Types.FETCH_GATEWAYS_HARDWARESTATUS_SUCCEEDED,
  Types.FETCH_GATEWAYS_HARDWARESTATUS_FAILED
)<void, Record<string, HardwareStatus[]>, void>();

export const fetchGatewaysSessionsStatus = createAsyncAction(
  Types.FETCH_GATEWAYS_SESSIONSSTATUS,
  Types.FETCH_GATEWAYS_SESSIONSSTATUS_SUCCEEDED,
  Types.FETCH_GATEWAYS_SESSIONSSTATUS_FAILED
)<void, Record<string, SessionStatus[]>, void>();

export const fetchSensorGroups = createAsyncAction(
  Types.FETCH_SENSORGROUPS,
  Types.FETCH_SENSORGROUPS_SUCCEEDED,
  Types.FETCH_SENSORGROUPS_FAILED
)<void, SensorGroup[], void>();
