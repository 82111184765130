import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import { format } from 'date-fns';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Notification from '@models/Notification';
import Loading from '../../../utils/Loading';

interface IProps {
  dismissNotifications: (id: string[]) => void;
  notifications: Notification[];
  loading: boolean;
}
interface IState {
  start: number;
}

class NotificationsList extends Component<IProps, IState> {
  private numberOfItems: number = 10;

  constructor(props: IProps) {
    super(props);

    this.state = {
      start: 0,
    };

    this.updateLoadedList = this.updateLoadedList.bind(this);
  }

  public componentDidUpdate(prevProps: any) {
    const { notifications } = this.props;

    if (
      JSON.stringify(notifications) !== JSON.stringify(prevProps.notifications)
    ) {
      this.updateLoadedList();
    }
  }

  public handleDismissClick(id: string) {
    return (event: any) => {
      event.stopPropagation();

      const { dismissNotifications } = this.props;

      dismissNotifications([id]);
    };
  }

  public getNotificationList(): Notification[] {
    const { start } = this.state;
    const { notifications } = this.props;

    return notifications
      .sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
      )
      .slice(0, start * this.numberOfItems + this.numberOfItems);
  }

  public updateLoadedList(start: number = 0) {
    this.setState({ start });
  }

  public render() {
    const { start } = this.state;
    const { loading } = this.props;

    const list = this.getNotificationList();
    const hasMoreItems =
      start * this.numberOfItems + this.numberOfItems === list.length;

    return !hasMoreItems && list.length === 0 ? (
      <Box p={2} pt={1} pb={1}>
        <Typography
          variant="body1"
          style={{ fontStyle: 'italic', color: '#999' }}
        >
          No unread notifications.
        </Typography>
      </Box>
    ) : (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.updateLoadedList}
        hasMore={hasMoreItems}
        loader={<LinearProgress key="loader" variant="query" />}
        useWindow={false}
        threshold={30}
      >
        <List key="list">
          {list.map((n) => {
            return (
              <ListItem
                key={n.id}
                button
                style={{
                  borderLeft:
                    n.type === 'system_error'
                      ? '5px solid rgba(255, 0, 0, 0.3)'
                      : '5px solid rgba(255, 165, 0, 0.3)',
                  padding: '0px 10px',
                  opacity: n.dismissed !== true ? 1 : 0.2,
                }}
              >
                <ListItemIcon>
                  <Avatar
                    style={{
                      backgroundColor: '#F9F9F9',
                      border: '1px solid #E7E7E7',
                    }}
                  >
                    {n.type === 'system_error' ? (
                      <ErrorIcon style={{ color: 'red' }} />
                    ) : (
                      <WarningIcon
                        style={{ color: 'orange', marginBottom: '3px' }}
                      />
                    )}
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={n.content}
                  secondary={format(new Date(n.created), 'yyyy-MM-dd HH:mm')}
                  style={{ whiteSpace: 'normal' }}
                />
                <Box p={2}>
                  <Loading loading={loading}>
                    {n.dismissed !== true ? (
                      <VisibilityOffIcon
                        style={{ cursor: 'pointer' }}
                        onClick={this.handleDismissClick(n.id)}
                      />
                    ) : null}
                  </Loading>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </InfiniteScroll>
    );
  }
}

export default NotificationsList;
