import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/contactGroups';
import ContactGroup, { ContactGroups } from '@models/ContactGroup';
import { Form } from '@models/Form';

type Actions = ActionType<typeof types>;

interface IContactGroupForm extends Form {
  data?: ContactGroup;
}
export interface IContactGroupsState {
  readonly error?: boolean;
  readonly form: IContactGroupForm;
  readonly loading: boolean;
  readonly contactGroups: ContactGroups;
  readonly open: boolean;
}

const initialState: IContactGroupsState = {
  contactGroups: {},
  form: { open: false, loading: false },
  loading: false,
  open: false,
};

export default (
  state: IContactGroupsState = initialState,
  action: Actions
): IContactGroupsState => {
  switch (action.type) {
    case getType(types.createContactGroup.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };
    case getType(types.createContactGroup.success): {
      const { contactGroups } = state;
      const { id } = action.payload;
      contactGroups[id || ''] = action.payload;

      return {
        ...state,
        contactGroups,
        form: {
          error: undefined,
          loading: false,
          open: false,
        },
      };
    }
    case getType(types.createContactGroup.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: true,
          loading: false,
        },
      };
    case getType(types.updateContactGroup.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };
    case getType(types.updateContactGroup.success): {
      const { contactGroups } = state;
      const { id } = action.payload;
      contactGroups[id || ''] = action.payload;

      return {
        ...state,
        contactGroups,
        form: {
          error: undefined,
          loading: false,
          open: false,
        },
      };
    }
    case getType(types.updateContactGroup.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: true,
          loading: false,
        },
      };

    case getType(types.deleteContactGroup.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case getType(types.deleteContactGroup.success): {
      const { id } = action.payload;
      const { contactGroups } = state;

      delete contactGroups[id];

      return {
        ...state,
        contactGroups,
        loading: false,
      };
    }
    case getType(types.deleteContactGroup.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.fetchContactGroups.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case getType(types.fetchContactGroups.success): {
      const contactGroups = {} as Record<string, ContactGroup>;
      action.payload.forEach((g) => {
        contactGroups[g.id || ''] = g;
      });

      return {
        ...state,
        contactGroups,
        loading: false,
      };
    }
    case getType(types.fetchContactGroups.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.openContactGroupForm):
      return {
        ...state,
        form: {
          ...state.form,
          data: action.payload as ContactGroup,
          open: true,
        },
      };

    case getType(types.closeContactGroupForm):
      return {
        ...state,
        form: {
          ...state.form,
          open: false,
        },
      };

    case getType(types.openContactGroups):
      return { ...state, open: true };

    case getType(types.closeContactGroups):
      return { ...state, open: false };

    default:
      return state;
  }
};
