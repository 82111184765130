import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/tickets';
import { Form } from '@models/Form';
import Ticket, { TicketComment, Tickets } from '@models/Ticket';
import { omit } from 'lodash';

type Actions = ActionType<typeof types>;

export interface ITicketState {
  readonly tickets: Tickets;
  readonly form: ITicketForm;
  readonly loading: boolean;
  readonly error?: boolean;
}

interface ITicketForm extends Form {
  redirect: boolean;
}

const initialState: ITicketState = {
  tickets: {},
  form: {
    loading: false,
    open: false,
    redirect: false,
  },
  loading: false,
};

export default (
  state: ITicketState = initialState,
  action: Actions
): ITicketState => {
  switch (action.type) {
    case getType(types.fetchTickets.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.fetchTickets.success): {
      const rawTicket: any = action.payload;
      const tickets = {} as Record<string, Ticket>;
      rawTicket.forEach((t: any) => {
        const comments: Record<string, TicketComment> = {};
        (t.comments || []).forEach((c: any) => {
          comments[c.id] = c;
        });

        tickets[t.id || ''] = { ...t, comments };
      });

      return {
        ...state,
        tickets,
        loading: false,
      };
    }

    case getType(types.fetchTickets.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.archiveTicket.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
          redirect: false,
        },
      };

    case getType(types.archiveTicket.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: false,
          redirect: true,
        },
        tickets: omit(state.tickets, id),
      };
    }

    case getType(types.archiveTicket.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: true,
          loading: false,
        },
      };

    case getType(types.createTicketComment.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createTicketComment.success): {
      const { comment } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: false,
        },
        tickets: {
          ...state.tickets,
          [comment.ticketId]: {
            ...(state.tickets[comment.ticketId] || {}),
            comments: {
              ...((state.tickets[comment.ticketId] || {}).comments || {}),
              [comment.id]: comment,
            },
          },
        },
      };
    }

    case getType(types.createTicketComment.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: true,
          loading: false,
        },
      };

    case getType(types.deleteTicketComment.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.deleteTicketComment.success): {
      const { ticketId, id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: false,
        },
        tickets: {
          ...state.tickets,
          [ticketId]: {
            ...(state.tickets[ticketId] || {}),
            comments: omit((state.tickets[ticketId] || {}).comments, id),
          },
        },
      };
    }

    case getType(types.deleteTicketComment.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: true,
          loading: false,
        },
      };

    default:
      return state;
  }
};
