import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { logout } from '@actions/index';
import { IAppState } from '@reducers/index';
import AuthPage from './Auth';
import { withRouter } from '../utils/withRouter';

const mapStateToProps = ({ authentication }: IAppState) => ({
  account: authentication.account,
  loading: authentication.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    logout: () => dispatch(logout.request()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthPage)
);
