import { ICreateOrganisation, IUpdateOrganisation } from '@actions/index';
import { Organisation, Organisations } from '@models/Organisation';
import axios from './axios';

export async function activateOrganisation(
  id: string,
  active: boolean
): Promise<void> {
  await axios.put('/organisations/activate', {
    active,
    id,
  });
}

export async function createOrganisation(
  properties: ICreateOrganisation
): Promise<Organisation> {
  const { data } = await axios.post('/organisations/', properties);

  return data;
}

export async function fetchOrganisations(): Promise<Organisations> {
  const { data } = await axios.get('/organisations');

  return data;
}

export async function updateOrganisation(
  properties: IUpdateOrganisation
): Promise<void> {
  await axios.put('/organisations/', properties);
}
