import { call, put } from 'redux-saga/effects';

import { ActionType } from 'typesafe-actions';
import * as types from '@actions/tickets';
import {
  fetchTickets,
  archiveTicket,
  createTicketComment,
  deleteTicketComment,
} from '@api/tickets';
import Ticket, { TicketComment } from '@models/Ticket';

export function* fetchTicketsSaga(
  action: ActionType<typeof types.fetchTickets.request>
) {
  const { ticketStatus } = action.payload;

  try {
    const tickets: Ticket[] = yield call(fetchTickets, ticketStatus);

    yield put(types.fetchTickets.success(tickets));
  } catch (error) {
    yield put(types.fetchTickets.failure());
  }
}

export function* archiveTicketSaga(
  action: ActionType<typeof types.archiveTicket.request>
) {
  const { organisationId, id } = action.payload;

  try {
    yield call(archiveTicket, organisationId, id);

    yield put(types.archiveTicket.success({ id }));
  } catch (error) {
    yield put(types.archiveTicket.failure());
  }
}

export function* createTicketCommentSaga(
  action: ActionType<typeof types.createTicketComment.request>
) {
  const { organisationId, ticketId, comment } = action.payload;

  try {
    const c: TicketComment = yield call(
      createTicketComment,
      organisationId,
      ticketId,
      comment
    );

    yield put(types.createTicketComment.success({ comment: c }));
  } catch (error) {
    yield put(types.createTicketComment.failure());
  }
}

export function* deleteTicketCommentSaga(
  action: ActionType<typeof types.deleteTicketComment.request>
) {
  const { organisationId, ticketId, id } = action.payload;

  try {
    yield call(deleteTicketComment, organisationId, ticketId, id);

    yield put(types.deleteTicketComment.success({ ticketId, id }));
  } catch (error) {
    yield put(types.deleteTicketComment.failure());
  }
}
