import { combineReducers } from 'redux';

import accounts, { IAccountsState } from './accounts';
import alerts, { IAlertState } from './alerts';
import app, { IAppState as IAppCState } from './app';
import authentication, { IAuthenticationState } from './authentication';
import contactGroups, { IContactGroupsState } from './contactGroups';
import data, { IDataState } from './data';
import gateways, { IGatewaysState } from './gateways';
import notifications, { INotificationsState } from './notifications';
import organisations, { IOrganisationsState } from './organisations';
import kafka, { IKafkaState } from './kafka';
import system, { ISystemState } from './system';
import tests, { ITestsState } from './tests';
import tickets, { ITicketState } from './tickets';

export enum RequestStatus {
  inProgress,
  failed,
  succeeded,
}

export interface IAppState {
  accounts: IAccountsState;
  alerts: IAlertState;
  app: IAppCState;
  authentication: IAuthenticationState;
  contactGroups: IContactGroupsState;
  data: IDataState;
  gateways: IGatewaysState;
  notifications: INotificationsState;
  organisations: IOrganisationsState;
  kafka: IKafkaState;
  system: ISystemState;
  tests: ITestsState;
  tickets: ITicketState;
}

export default combineReducers({
  accounts,
  alerts,
  app,
  authentication,
  contactGroups,
  data,
  gateways,
  notifications,
  organisations,
  kafka,
  system,
  tests,
  tickets,
});
