import { createAction, createAsyncAction } from 'typesafe-actions';

import ContactGroup from '@models/ContactGroup';
import { IState as ICreateContactGroup } from '../components/forms/ContactGroupForm/ContactGroupForm';

enum Types {
  CREATE_CONTACT_GROUP = '@@contactGroups/CREATE_CONTACT_GROUP',
  CREATE_CONTACT_GROUP_SUCCEEDED = '@@contactGroups/CREATE_CONTACT_GROUP_SUCCEEDED',
  CREATE_CONTACT_GROUP_FAILED = '@@contactGroups/CREATE_CONTACT_GROUP_FAILED',

  UPDATE_CONTACT_GROUP = '@@contactGroups/UPDATE_CONTACT_GROUP',
  UPDATE_CONTACT_GROUP_SUCCEEDED = '@@contactGroups/UPDATE_CONTACT_GROUP_SUCCEEDED',
  UPDATE_CONTACT_GROUP_FAILED = '@@contactGroups/UPDATE_CONTACT_GROUP_FAILED',

  DELETE_CONTACT_GROUP = '@@contactGroups/DELETE_CONTACT_GROUP',
  DELETE_CONTACT_GROUP_SUCCEEDED = '@@contactGroups/DELETE_CONTACT_GROUP_SUCCEEDED',
  DELETE_CONTACT_GROUP_FAILED = '@@contactGroups/DELETE_CONTACT_GROUP_FAILED',

  FETCH_CONTACT_GROUPS = '@@contactGroups/FETCH_CONTACT_GROUPS',
  FETCH_CONTACT_GROUPS_SUCCEEDED = '@@contactGroups/FETCH_CONTACT_GROUPS_SUCCEEDED',
  FETCH_CONTACT_GROUPS_FAILED = '@@contactGroups/FETCH_CONTACT_GROUPS_FAILED',

  OPENFORM = '@@contactGroups/OPENFORM',
  CLOSEFORM = '@@contactGroups/CLOSEFORM',

  OPEN = '@@contactGroups/OPEN',
  CLOSE = '@@contactGroups/CLOSE',
}

export interface IUpdateContactGroup extends ICreateContactGroup {
  id: string;
}
export interface IDelete {
  id: string;
}

export const createContactGroup = createAsyncAction(
  Types.CREATE_CONTACT_GROUP,
  Types.CREATE_CONTACT_GROUP_SUCCEEDED,
  Types.CREATE_CONTACT_GROUP_FAILED
)<ICreateContactGroup, ContactGroup, void>();

export const updateContactGroup = createAsyncAction(
  Types.UPDATE_CONTACT_GROUP,
  Types.UPDATE_CONTACT_GROUP_SUCCEEDED,
  Types.UPDATE_CONTACT_GROUP_FAILED
)<IUpdateContactGroup, ContactGroup, void>();

export const deleteContactGroup = createAsyncAction(
  Types.DELETE_CONTACT_GROUP,
  Types.DELETE_CONTACT_GROUP_SUCCEEDED,
  Types.DELETE_CONTACT_GROUP_FAILED
)<IDelete, IDelete, IDelete>();

export const fetchContactGroups = createAsyncAction(
  Types.FETCH_CONTACT_GROUPS,
  Types.FETCH_CONTACT_GROUPS_SUCCEEDED,
  Types.FETCH_CONTACT_GROUPS_FAILED
)<void, ContactGroup[], void>();

export const openContactGroupForm = createAction(
  Types.OPENFORM,
  (data?: ContactGroup) => data
)();
export const closeContactGroupForm = createAction(Types.CLOSEFORM)();

export const openContactGroups = createAction(Types.OPEN)();
export const closeContactGroups = createAction(Types.CLOSE)();
