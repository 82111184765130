import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/notifications';
import Notification from '@models/Notification';

type Actions = ActionType<typeof types>;

export interface INotificationsState {
  readonly error?: boolean;
  readonly loading: boolean;
  readonly notifications?: Notification[];
  readonly dismissError?: boolean;
  readonly dismissLoading: boolean;
}

const initialState: INotificationsState = {
  loading: false,
  dismissLoading: false,
};

export default (
  state: INotificationsState = initialState,
  action: Actions
): INotificationsState => {
  switch (action.type) {
    case getType(types.fetchNotifications.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.fetchNotifications.success):
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      };

    case getType(types.fetchNotifications.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.dismissNotifications.request):
      return {
        ...state,
        dismissError: undefined,
        dismissLoading: true,
      };

    case getType(types.dismissNotifications.success): {
      const notifications: Notification[] = [];
      (state.notifications || []).forEach((notification) => {
        const index = action.payload.indexOf(notification.id);
        if (index === -1) {
          notifications.push(notification);
        }
      });

      return {
        ...state,
        notifications,
        dismissError: undefined,
        dismissLoading: false,
      };
    }

    case getType(types.dismissNotifications.failure):
      return {
        ...state,
        dismissError: undefined,
        dismissLoading: false,
      };

    default:
      return state;
  }
};
