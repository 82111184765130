import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import React, { FunctionComponent } from 'react';

import './index.css';

interface IProps extends ButtonProps {
  disabled?: boolean;
  id?: string;
  onClick?: () => void;
  loading: boolean;
  children: React.ReactNode;
  circularProgressColor?: CircularProgressProps['color'];
}

const LoadingButton: FunctionComponent<IProps> = ({
  children,
  circularProgressColor = 'primary',
  color,
  disabled = false,
  id,
  loading,
  // tslint:disable-next-line no-empty
  onClick = () => {},
  type = 'button',
  variant = 'text',
}) => (
  <div className="loading-button-wrapper">
    <Button
      color={color}
      disabled={disabled || loading}
      id={id}
      onClick={onClick}
      type={type}
      variant={variant}
    >
      {children}
    </Button>
    {loading && (
      <CircularProgress
        className="loading-button"
        color={circularProgressColor}
        size={24}
      />
    )}
  </div>
);
LoadingButton.defaultProps = {
  disabled: undefined,
  id: undefined,
  onClick: undefined,
  circularProgressColor: undefined,
};

export default LoadingButton;
