import { createAsyncAction } from 'typesafe-actions';

import Ticket, { TicketComment } from '@models/Ticket';

enum Types {
  FETCH_TICKET = '@@tickets/FETCH_TICKET',
  FETCH_TICKET_SUCCEEDED = '@@tickets/FETCH_TICKET_SUCCEEDED',
  FETCH_TICKET_FAILED = '@@tickets/FETCH_TICKET_FAILED',

  ARCHIVE_TICKET = '@@tickets/ARCHIVE_TICKET',
  ARCHIVE_TICKET_SUCCEEDED = '@@tickets/ARCHIVE_TICKET_SUCCEEDED',
  ARCHIVE_TICKET_FAILED = '@@tickets/ARCHIVE_TICKET_FAILED',

  CREATE_COMMENT = '@@tickets/CREATE_COMMENT',
  CREATE_COMMENT_SUCCEEDED = '@@tickets/CREATE_COMMENT_SUCCEEDED',
  CREATE_COMMENT_FAILED = '@@tickets/CREATE_COMMENT_FAILED',

  DELETE_COMMENT = '@@tickets/DELETE_COMMENT',
  DELETE_COMMENT_SUCCEEDED = '@@tickets/DELETE_COMMENT_SUCCEEDED',
  DELETE_COMMENT_FAILED = '@@tickets/DELETE_COMMENT_FAILED',
}

export interface FetchTickets {
  ticketStatus: 'open' | 'archived';
}
export const fetchTickets = createAsyncAction(
  Types.FETCH_TICKET,
  Types.FETCH_TICKET_SUCCEEDED,
  Types.FETCH_TICKET_FAILED
)<FetchTickets, Ticket[], void>();

export interface ArchiveTicket {
  organisationId: string;
  id: string;
}
interface ArchiveTicketSuccess {
  id: string;
}
export const archiveTicket = createAsyncAction(
  Types.ARCHIVE_TICKET,
  Types.ARCHIVE_TICKET_SUCCEEDED,
  Types.ARCHIVE_TICKET_FAILED
)<ArchiveTicket, ArchiveTicketSuccess, void>();

export interface CreateTicketComment {
  organisationId: string;
  ticketId: string;
  comment: string;
}
interface TicketCommentSuccess {
  comment: TicketComment;
}

export const createTicketComment = createAsyncAction(
  Types.CREATE_COMMENT,
  Types.CREATE_COMMENT_SUCCEEDED,
  Types.CREATE_COMMENT_FAILED
)<CreateTicketComment, TicketCommentSuccess, void>();

export interface DeleteTicketComment {
  organisationId: string;
  ticketId: string;
  id: string;
}
interface DeleteTicketCommentSuccess {
  ticketId: string;
  id: string;
}

export const deleteTicketComment = createAsyncAction(
  Types.DELETE_COMMENT,
  Types.DELETE_COMMENT_SUCCEEDED,
  Types.DELETE_COMMENT_FAILED
)<DeleteTicketComment, DeleteTicketCommentSuccess, void>();
