import { createAction, createAsyncAction } from 'typesafe-actions';

import FetchError from '@models/FetchError';
import { Organisation, Organisations } from '@models/Organisation';

enum Types {
  ACTIVATE_ORGANISATION = '@@organisations/ACTIVATE_ORGANISATION',
  ACTIVATE_ORGANISATION_SUCCEEDED = '@@organisations/ACTIVATE_ORGANISATION_SUCCEEDED',
  ACTIVATE_ORGANISATION_FAILED = '@@organisations/ACTIVATE_ORGANISATION_FAILED',

  CREATE_ORGANISATION = '@@organisations/CREATE_ORGANISATION',
  CREATE_ORGANISATION_SUCCEEDED = '@@organisations/CREATE_ORGANISATION_SUCCEEDED',
  CREATE_ORGANISATION_FAILED = '@@organisations/CREATE_ORGANISATION_FAILED',

  FETCH_ORGANISATIONS = '@@organisations/FETCH_ORGANISATIONS',
  FETCH_ORGANISATIONS_SUCCEEDED = '@@organisations/FETCH_ORGANISATIONS_SUCCEEDED',
  FETCH_ORGANISATIONS_FAILED = '@@organisations/FETCH_ORGANISATIONS_FAILED',

  UPDATE_ORGANISATION = '@@organisations/UPDATE_ORGANISATION',
  UPDATE_ORGANISATION_SUCCEEDED = '@@organisations/UPDATE_ORGANISATION_SUCCEEDED',
  UPDATE_ORGANISATION_FAILED = '@@organisations/UPDATE_ORGANISATION_FAILED',

  OPENFORM = '@@organisations/OPENFORM',
  CLOSEFORM = '@@organisations/CLOSEFORM',

  OPEN_ANALYTICS = '@@organisations/OPEN_ANALYTICS',
  CLOSE_ANALYTICS = '@@organisations/CLOSE_ANALYTICS',

  UPDATE_ORGANISATION_ANALYTICS = '@@organisations/UPDATE_ORGANISATION_ANALYTICS',
  UPDATE_ORGANISATION_ANALYTICS_SUCCEEDED = '@@organisations/UPDATE_ORGANISATION_ANALYTICS_SUCCEEDED',
  UPDATE_ORGANISATION_ANALYTICS_FAILED = '@@organisations/UPDATE_ORGANISATION_ANALYTICS_FAILED',
}

export interface IActivateOrganisation {
  id: string;
  active: boolean;
}

export interface ICreateOrganisation {
  id: string;
  name: string;
}
export interface IUpdateOrganisation {
  id: string;
  name: string;
  active: boolean;
}

interface IError {
  error: FetchError;
}

export const activateOrganisation = createAsyncAction(
  Types.ACTIVATE_ORGANISATION,
  Types.ACTIVATE_ORGANISATION_SUCCEEDED,
  Types.ACTIVATE_ORGANISATION_FAILED
)<IActivateOrganisation, IActivateOrganisation, void>();

export const createOrganisation = createAsyncAction(
  Types.CREATE_ORGANISATION,
  Types.CREATE_ORGANISATION_SUCCEEDED,
  Types.CREATE_ORGANISATION_FAILED
)<ICreateOrganisation, Organisation, IError>();

export const fetchOrganisations = createAsyncAction(
  Types.FETCH_ORGANISATIONS,
  Types.FETCH_ORGANISATIONS_SUCCEEDED,
  Types.FETCH_ORGANISATIONS_FAILED
)<void, Organisations, void>();

export const updateOrganisation = createAsyncAction(
  Types.UPDATE_ORGANISATION,
  Types.UPDATE_ORGANISATION_SUCCEEDED,
  Types.UPDATE_ORGANISATION_FAILED
)<IUpdateOrganisation, IUpdateOrganisation, IError>();

export const openOrganisationForm = createAction(
  Types.OPENFORM,
  (data?: Organisation) => data
)();
export const closeOrganisationForm = createAction(Types.CLOSEFORM)();
