import Gateway from '@models/Gateway';
import HardwareStatus from '@models/HardwareStatus';
import SensorGroup from '@models/SensorGroup';
import SessionStatus from '@models/SessionStatus';
import axios from './axios';

export async function fetchGateways(): Promise<Record<string, Gateway[]>> {
  const { data } = await axios.get('/gateways');

  return data;
}

/* eslint-disable @typescript-eslint/indent */
export async function fetchGatewaysHardwareStatus(): Promise<HardwareStatus[]> {
  /* eslint-enable @typescript-eslint/indent */
  const { data } = await axios.get('/gateways/hardwarestatus');

  return data;
}

/* eslint-disable @typescript-eslint/indent */
export async function fetchGatewaysSessionsStatus(): Promise<
  Record<string, SessionStatus[]>
> {
  /* eslint-enable @typescript-eslint/indent */
  const { data } = await axios.get('/gateways/sessionstatus');

  return data;
}

export async function fetchSensorGroups(): Promise<SensorGroup[]> {
  const { data } = await axios.get('/gateways/sensorgroups');

  return data;
}
