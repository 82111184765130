import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchAccount } from '@actions/index';
import { IAppState } from '@reducers/index';
import App from './App';

import './App.css';

const mapStateToProps = (state: IAppState) => ({
  account: state.authentication.account,
  authenticated: state.authentication.authenticated,
  firstAccountCheck: state.authentication.firstAccountCheck,
  theme: state.app.theme,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccount: () => dispatch(fetchAccount.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
