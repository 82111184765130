import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/notifications';
import { fetchNotifications, dismissNotifications } from '@api/notifications';
import Notification from '@models/Notification';

export function* fetchNotificationsSaga() {
  try {
    const notifications: Notification[] = yield call(fetchNotifications);

    yield put(types.fetchNotifications.success(notifications));
  } catch (error) {
    yield put(types.fetchNotifications.failure());
  }
}

export function* dismissNotificationsSaga(
  action: ActionType<typeof types.dismissNotifications.request>
) {
  try {
    yield call(dismissNotifications, action.payload);

    yield put(types.dismissNotifications.success(action.payload));
  } catch (error) {
    yield put(types.dismissNotifications.failure());
  }
}
