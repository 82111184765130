import Notification from '@models/Notification';
import axios from './axios';

export async function fetchNotifications(): Promise<Notification[]> {
  const { data } = await axios.get('/notifications');

  return data;
}

export async function dismissNotifications(ids: string[]): Promise<void> {
  await axios.put('/notifications/dismiss', ids);
}
