import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/accounts';
import {
  activateAccount,
  changeAccountAdmin,
  fetchAccounts,
  updateAccountOrganisations,
} from '@api/accounts';

export function* activateAccountSaga(
  action: ActionType<typeof types.activateAccount.request>
) {
  const { active, id } = action.payload;

  try {
    yield call(activateAccount, id, active);

    yield put(types.activateAccount.success({ active, id }));
  } catch (error) {
    yield put(types.activateAccount.failure());
  }
}

export function* changeAccountAdminSaga(
  action: ActionType<typeof types.changeAccountAdmin.request>
) {
  const { admin, id } = action.payload;

  try {
    yield call(changeAccountAdmin, id, admin);

    yield put(types.changeAccountAdmin.success({ admin, id }));
  } catch (error) {
    yield put(types.changeAccountAdmin.failure());
  }
}

export function* updateAccountOrganisationsSaga(
  action: ActionType<typeof types.updateAccountOrganisations.request>
) {
  const { id, organisations } = action.payload;

  try {
    yield call(updateAccountOrganisations, id, organisations);

    yield put(types.updateAccountOrganisations.success({ id, organisations }));
  } catch (error) {
    yield put(types.updateAccountOrganisations.failure());
  }
}

export function* fetchAccountsSaga(): any {
  try {
    const accounts = yield call(fetchAccounts);

    yield put(types.fetchAccounts.success(accounts));
  } catch (error) {
    yield put(types.fetchAccounts.failure());
  }
}
