import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { confirmDialogClose } from '@actions/index';
import { IAppState } from '@reducers/index';
import { ConfirmDialogActions } from './types';
import ConfirmDialog from './ConfirmDialog';

const defaultFunction = () => undefined;

const mapStateToProps = (state: IAppState) => ({
  confirmType:
    (state.app.confirmDialog.formData || {}).confirmType ||
    ConfirmDialogActions.DELETE,
  deletionConfirm: state.app.confirmDialog,
  message: (state.app.confirmDialog.formData || {}).message || '',
  onCancelation: (state.app.confirmDialog.formData || {}).onCancelation,
  onConfirmation:
    (state.app.confirmDialog.formData || {}).onConfirmation || defaultFunction,
  open: state.app.confirmDialog.open,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => dispatch(confirmDialogClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
