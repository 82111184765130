import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Account from '@models/Account';
import Auth from '../Auth';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import Menu from '../Menu';
import shortcutsState, { updateShortCuts } from '../Menu/shortcuts';
import Navbar from '../Navbar';
import ShortCutMenu from '../ShortCutMenu';
import Tabs from '../Tabs';
import { PRIMARY_COLOR } from '../utils/colors';

createBrowserHistory();

const getTheme = (mode: string) =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: mode === 'dark' ? PRIMARY_COLOR : '#000000',
      },
    },
    overrides: {
      MUIDataTable: {
        paper: {
          boxShadow: 'none',
          flex: 1,
        },
      },
      MuiTableCell: {
        head: {
          fontWeight: 700,
        },
        root: {
          padding: 8,
        },
      },
    },
  } as ThemeOptions);

interface IProps {
  account?: Account;
  authenticated: boolean;
  fetchAccount: () => void;
  firstAccountCheck: boolean;
  theme: string;
}

interface IState {
  shortcuts: Record<string, boolean>;
}

class App extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      shortcuts: shortcutsState,
    };

    this.updateShortcut = this.updateShortcut.bind(this);
  }

  public componentDidMount() {
    const { fetchAccount } = this.props;

    fetchAccount();
  }

  public updateShortcut(id: string) {
    const { shortcuts } = this.state;

    const data = { ...shortcuts, [id]: !shortcuts[id] };

    this.setState({ shortcuts: data }, () => updateShortCuts(data));
  }

  public render() {
    const { account, authenticated, firstAccountCheck, theme } = this.props;
    const { shortcuts } = this.state;

    return (
      <ThemeProvider theme={getTheme(theme)}>
        <LocalizationProvider dateAdapter={AdapterDateFns} />
        <CssBaseline>
          <BrowserRouter>
            <Navbar />
            <Menu
              shortcuts={shortcuts}
              updateShortcut={this.updateShortcut}
            />
            {firstAccountCheck ? (
              <>
                {authenticated && (account || ({} as Account)).admin ? (
                  <>
                    <Paper className="dashboard-wrapper">
                      <ShortCutMenu shortcuts={shortcuts} />
                      <Box className="tab-content" p={2} pt={1}>
                        <Tabs />
                      </Box>
                    </Paper>
                    <ConfirmDialog />
                  </>
                ) : (
                  <Auth />
                )}
              </>
            ) : (
              <LinearProgress />
            )}
          </BrowserRouter>
        </CssBaseline>
      </ThemeProvider>
    );
  }
}

export default App;
