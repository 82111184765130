import {
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from '../utils/localStorageUtils';

const shortCuts: Record<string, boolean> = (getObjectFromLocalStorage(
  'shortcuts'
) as Record<string, boolean>) || {
  organisations: true,
  accounts: true,
  tickets: true,
  kafka: false,
  loadtests: false,
  dumpdata: false,
  infrastructure: false,
  status: false,
  alerts: true,
  gateways: true,
  software: true,
  calendar: false,
  settings: true,
};

export const updateShortCuts = (data: Record<string, boolean>) => {
  saveObjectToLocalStorage('shortcuts', data);
};

export default shortCuts;
