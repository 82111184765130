import { ICreateAlert, IResetAlert, IUpdateAlert } from '@actions/index';
import Alert from '@models/Alert';
import axios from './axios';

export async function createAlert(properties: ICreateAlert): Promise<Alert> {
  const { data } = await axios.post('/alerts', properties);

  return data;
}

export async function activateAlert(id: string): Promise<Alert> {
  const { data } = await axios.put('/alerts/activate', { id });

  return data;
}

export async function deactivateAlert(id: string): Promise<Alert> {
  const { data } = await axios.put('/alerts/deactivate', { id });

  return data;
}

export async function deleteAlert(id: string): Promise<void> {
  await axios.delete(`/alerts/id/${id}`);
}

export async function fetchAlerts(): Promise<Alert[]> {
  const { data } = await axios.get('/alerts');

  return data;
}

export async function updateAlert(properties: IUpdateAlert): Promise<Alert> {
  const { data } = await axios.put('/alerts', properties);

  return data;
}

export async function resetAlert(properties: IResetAlert): Promise<Alert> {
  const { data } = await axios.put('/alerts/reset', properties);

  return data;
}
