import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/alerts';
import Alert, { Alerts } from '@models/Alert';
import { Form } from '@models/Form';

type Actions = ActionType<typeof types>;

export interface IAlertState {
  readonly alerts: Alerts;
  readonly form: IAlertForm;
  readonly loading: boolean;
  readonly error?: boolean;
}

interface IAlertForm extends Form {
  data?: Alert;
}

const initialState: IAlertState = {
  alerts: {},
  form: {
    loading: false,
    open: false,
  },
  loading: false,
};

export default (
  state: IAlertState = initialState,
  action: Actions
): IAlertState => {
  switch (action.type) {
    case getType(types.createAlert.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createAlert.success): {
      const alert = action.payload;

      return {
        ...state,
        alerts: {
          ...state.alerts,
          [alert.id as string]: alert,
        },
        form: {
          ...state.form,
          loading: false,
          open: false,
        },
      };
    }

    case getType(types.createAlert.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: true,
          loading: false,
        },
      };

    case getType(types.activateAlert.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.activateAlert.success): {
      const { alerts } = state;
      const { id } = action.payload;

      return {
        ...state,
        alerts: {
          ...alerts,
          [id]: {
            ...alerts[id],
            active: true,
          },
        },
        loading: false,
      };
    }

    case getType(types.activateAlert.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.deactivateAlert.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deactivateAlert.success): {
      const { alerts } = state;
      const { id } = action.payload;

      return {
        ...state,
        alerts: {
          ...alerts,
          [id]: {
            ...alerts[id],
            active: false,
          },
        },
        loading: false,
      };
    }

    case getType(types.deactivateAlert.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.deleteAlert.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.deleteAlert.success): {
      const { id } = action.payload;
      const { alerts } = state;

      delete alerts[id];

      return {
        ...state,
        alerts,
        loading: false,
      };
    }

    case getType(types.deleteAlert.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.fetchAlerts.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.fetchAlerts.success): {
      const alerts = {} as Record<string, Alert>;
      action.payload.forEach((g) => {
        alerts[g.id || ''] = g;
      });

      return {
        ...state,
        alerts,
        loading: false,
      };
    }

    case getType(types.fetchAlerts.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.resetAlert.request):
      return {
        ...state,
        loading: true,
      };

    case getType(types.resetAlert.success): {
      const { alerts } = state;
      return {
        ...state,
        alerts: {
          ...alerts,
          [action.payload.id || '']: action.payload,
        },
        loading: false,
      };
    }

    case getType(types.resetAlert.failure):
      return {
        ...state,
        loading: false,
      };

    case getType(types.updateAlert.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.updateAlert.success): {
      const { alerts } = state;
      const { id } = action.payload;
      alerts[id || ''] = action.payload;

      return {
        ...state,
        alerts,
        form: {
          error: undefined,
          loading: false,
          open: false,
        },
      };
    }

    case getType(types.updateAlert.failure):
      return {
        ...state,
        form: {
          ...state.form,
          error: true,
          loading: false,
        },
      };

    case getType(types.openAlertForm):
      return {
        ...state,
        form: {
          ...state.form,
          data: action.payload as Alert,
          open: true,
        },
      };

    case getType(types.closeAlertForm):
      return {
        ...state,
        form: {
          ...state.form,
          open: false,
        },
      };

    default:
      return state;
  }
};
