import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/accounts';
import Account, { Accounts } from '@models/Accounts';

type Actions = ActionType<typeof types>;

export interface IAccountsState {
  readonly accounts?: Accounts;
  readonly loading: boolean;
  readonly error?: boolean;
}

const initialState: IAccountsState = {
  loading: false,
};

export default (
  state: IAccountsState = initialState,
  action: Actions
): IAccountsState => {
  switch (action.type) {
    case getType(types.activateAccount.request):
      return {
        ...state,
        loading: true,
      };

    case getType(types.activateAccount.success): {
      const { active, id } = action.payload;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [id]: {
            ...(state.accounts || {})[id],
            active,
          },
        },
        loading: false,
      };
    }

    case getType(types.activateAccount.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.changeAccountAdmin.request):
      return {
        ...state,
        loading: true,
      };

    case getType(types.changeAccountAdmin.success): {
      const { admin, id } = action.payload;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [id]: {
            ...(state.accounts || {})[id],
            admin,
          },
        },
        loading: false,
      };
    }

    case getType(types.changeAccountAdmin.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.fetchAccounts.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(types.fetchAccounts.success): {
      const accounts = {} as Record<string, Account>;
      action.payload.forEach((g) => {
        accounts[g.id] = g;
      });

      return {
        ...state,
        accounts,
        loading: false,
      };
    }

    case getType(types.fetchAccounts.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.updateAccountOrganisations.request):
      return {
        ...state,
        loading: true,
      };

    case getType(types.updateAccountOrganisations.success): {
      const { id, organisations } = action.payload;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [id]: {
            ...(state.accounts || {})[id],
            organisations,
          },
        },
        loading: false,
      };
    }

    case getType(types.updateAccountOrganisations.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    default:
      return state;
  }
};
