import { call, put } from 'redux-saga/effects';

import * as types from '@actions/gateways';
import {
  fetchGateways,
  fetchGatewaysHardwareStatus,
  fetchGatewaysSessionsStatus,
  fetchSensorGroups,
} from '@api/gateways';
import Gateway from '@models/Gateway';
import HardwareStatus from '@models/HardwareStatus';
import SensorGroup from '@models/SensorGroup';
import SessionStatus from '@models/SessionStatus';

export function* fetchGatewaysSaga() {
  try {
    const gateways: Gateway[] = yield call(fetchGateways);

    yield put(types.fetchGateways.success(gateways));
  } catch (error) {
    yield put(types.fetchGateways.failure());
  }
}

export function* fetchGatewaysHardwareStatusSaga() {
  try {
    const gatewaysStatus: Record<string, HardwareStatus[]> = yield call(
      fetchGatewaysHardwareStatus
    );

    yield put(types.fetchGatewaysHardwareStatus.success(gatewaysStatus));
  } catch (error) {
    yield put(types.fetchGatewaysHardwareStatus.failure());
  }
}

export function* fetchSensorGroupsSaga() {
  try {
    const sensorGroups: SensorGroup[] = yield call(fetchSensorGroups);

    yield put(types.fetchSensorGroups.success(sensorGroups));
  } catch (error) {
    yield put(types.fetchSensorGroups.failure());
  }
}

export function* fetchGatewaysSessionsStatusSaga() {
  try {
    const sessionsStatus: Record<string, SessionStatus[]> = yield call(
      fetchGatewaysSessionsStatus
    );

    yield put(types.fetchGatewaysSessionsStatus.success(sessionsStatus));
  } catch (error) {
    yield put(types.fetchGatewaysSessionsStatus.failure());
  }
}
