import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/gateways';
import Gateway from '@models/Gateway';
import HardwareStatus from '@models/HardwareStatus';
import SensorGroup from '@models/SensorGroup';
import SessionStatus from '@models/SessionStatus';

type Actions = ActionType<typeof types>;

interface IGateways {
  readonly loading: boolean;
  readonly error?: boolean;
  readonly gateways?: Gateway[];
}

interface IHardwareStatus {
  readonly loading: boolean;
  readonly error?: boolean;
  readonly hardwareStatus: Record<string, HardwareStatus[]>;
}

interface ISensorGroups {
  readonly loading: boolean;
  readonly error?: boolean;
  readonly sensorGroups: Record<string, SensorGroup>;
}

interface ISessionsStatus {
  readonly loading: boolean;
  readonly error?: boolean;
  readonly sessionsStatus?: Record<string, SessionStatus[]>;
}

export interface IGatewaysState {
  readonly gateways: IGateways;
  readonly hardwareStatus: IHardwareStatus;
  readonly sensorGroups: ISensorGroups;
  readonly sessionsStatus: ISessionsStatus;
}

const initialState: IGatewaysState = {
  gateways: {
    loading: false,
  },
  hardwareStatus: {
    hardwareStatus: {},
    loading: false,
  },
  sensorGroups: {
    loading: false,
    sensorGroups: {},
  },
  sessionsStatus: {
    loading: false,
  },
};

export default (
  state: IGatewaysState = initialState,
  action: Actions
): IGatewaysState => {
  switch (action.type) {
    case getType(types.fetchGateways.request):
      return {
        ...state,
        gateways: {
          ...state.gateways,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchGateways.success):
      return {
        ...state,
        gateways: {
          gateways: action.payload,
          loading: false,
        },
      };

    case getType(types.fetchGateways.failure):
      return {
        ...state,
        gateways: {
          ...state.gateways,
          error: true,
          loading: false,
        },
      };

    case getType(types.fetchGatewaysHardwareStatus.request):
      return {
        ...state,
        hardwareStatus: {
          ...state.hardwareStatus,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchGatewaysHardwareStatus.success):
      return {
        ...state,
        hardwareStatus: {
          hardwareStatus: action.payload,
          loading: false,
        },
      };

    case getType(types.fetchGatewaysHardwareStatus.failure):
      return {
        ...state,
        hardwareStatus: {
          ...state.hardwareStatus,
          error: true,
          loading: false,
        },
      };

    case getType(types.fetchGatewaysSessionsStatus.request):
      return {
        ...state,
        sessionsStatus: {
          ...state.sessionsStatus,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchGatewaysSessionsStatus.success):
      return {
        ...state,
        sessionsStatus: {
          loading: false,
          sessionsStatus: action.payload,
        },
      };

    case getType(types.fetchGatewaysSessionsStatus.failure):
      return {
        ...state,
        sessionsStatus: {
          ...state.sessionsStatus,
          error: true,
          loading: false,
        },
      };

    case getType(types.fetchSensorGroups.request):
      return {
        ...state,
        sensorGroups: {
          ...state.sensorGroups,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchSensorGroups.success): {
      const sensorGroups = {} as Record<string, SensorGroup>;
      action.payload.forEach((g) => {
        sensorGroups[g.id || ''] = g;
      });

      return {
        ...state,
        sensorGroups: {
          loading: false,
          sensorGroups,
        },
      };
    }

    case getType(types.fetchSensorGroups.failure):
      return {
        ...state,
        sensorGroups: {
          ...state.sensorGroups,
          error: true,
          loading: false,
        },
      };

    default:
      return state;
  }
};
