import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/data';

type Actions = ActionType<typeof types>;

interface IState {
  readonly loading: boolean;
  readonly error?: boolean;
}
export interface IDataState {
  readonly dump: IState;
}

const initialState: IDataState = {
  dump: {
    loading: false,
  },
};

export default (
  state: IDataState = initialState,
  action: Actions
): IDataState => {
  switch (action.type) {
    case getType(types.fetchDataDump.request):
      return {
        ...state,
        dump: {
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchDataDump.success): {
      return {
        ...state,
        dump: {
          error: undefined,
          loading: false,
        },
      };
    }

    case getType(types.fetchDataDump.failure):
      return {
        ...state,
        dump: {
          error: true,
          loading: false,
        },
      };

    default:
      return state;
  }
};
