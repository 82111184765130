import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { toggleMenu } from '@actions/index';
import { IAppState } from '@reducers/index';

import Menu from './Menu';

import './index.css';

const mapStateToProps = (state: IAppState) => ({
  open: state.app.menu.open,
  theme: state.app.theme,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleMenu: (open: boolean) => dispatch(toggleMenu(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
