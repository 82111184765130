import axios from './axios';

export async function requestLoadTest(
  uuid: string,
  stressLevel: number
): Promise<void> {
  await axios.post('/tests/load', { uuid, stressLevel });
}

export default {};
