import CircularProgress from '@mui/material/CircularProgress';
import React, { FunctionComponent } from 'react';

interface IProps {
  loading: boolean;
  children?: React.ReactNode;
}

const Loading: FunctionComponent<IProps> = ({ loading, children }) =>
  loading ? (
    <div style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  ) : (
    <>{children}</>
  );

Loading.defaultProps = {
  children: undefined,
};
export default Loading;
