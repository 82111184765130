import { Accounts } from '@models/Accounts';
import axios from './axios';

export async function fetchAccounts(): Promise<Accounts> {
  const { data } = await axios.get('/accounts');

  return data;
}

export async function activateAccount(
  id: string,
  active: boolean
): Promise<void> {
  await axios.put('/accounts/activate', {
    active,
    id,
  });
}

export async function changeAccountAdmin(
  id: string,
  admin: boolean
): Promise<void> {
  await axios.put('/accounts/admin', {
    admin,
    id,
  });
}

export async function updateAccountOrganisations(
  id: string,
  organisations: string[]
): Promise<void> {
  await axios.put('/accounts/organisations', {
    id,
    organisations,
  });
}
