import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { logout, toggleMenu } from '@actions/index';
import { IAppState } from '@reducers/index';
import Navbar from './Navbar';

const mapStateToProps = (state: IAppState) => ({
  authenticated: state.authentication.authenticated,
  account: state.authentication.account,
  loading: state.authentication.loading,
  open: state.app.menu.open,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(logout.request()),
  toggleMenu: (open: boolean) => dispatch(toggleMenu(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
