import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { tabs } from './Menu/Menu';

interface IProps {
  shortcuts: Record<string, boolean>;
}

const ShortCutMenu: FunctionComponent<IProps> = ({ shortcuts }: IProps) => {
  let dashboardURL = 'https://dashboard.logidot.com';
  if (window.location.origin.indexOf('localhost') !== -1) {
    dashboardURL = 'http://localhost:3000';
  } else if (window.location.origin.indexOf('console.dev') !== -1) {
    dashboardURL = 'https://dashboard.dev.sonodot.com';
  }

  return (
    <Paper className="shortcut-menu">
      <List>
        {tabs
          .filter((tab) => shortcuts[tab.id])
          .map((tab) => (
            <Tooltip key={tab.link} title={tab.title}>
              <ListItem
                button
                component={Link}
                to={tab.link!}
                style={{ color: tab.shortcutColor }}
              >
                {tab.icon}
              </ListItem>
            </Tooltip>
          ))}
        <Tooltip title="Dashboard">
          <ListItem
            style={{ color: '#ff6633' }}
            button
            component="a"
            href={dashboardURL}
          >
            <DesktopMacIcon />
          </ListItem>
        </Tooltip>
      </List>
    </Paper>
  );
};

export default ShortCutMenu;
