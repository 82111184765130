import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { ActionType } from 'typesafe-actions';

import appReducer, { IAppState } from '@reducers/index';
import sagas from '@sagas/index';

const rootReducer = (state: any, action: ActionType<any>): IAppState => {
  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();
let middleware = applyMiddleware(sagaMiddleware);
if (process.env.NODE_ENV === 'development') {
  middleware = composeWithDevTools(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(createLogger())
  );
}
const store = createStore(rootReducer, middleware);

sagaMiddleware.run(sagas);

export default store;
