import { createAction, createAsyncAction } from 'typesafe-actions';

import Alert from '@models/Alert';

enum Types {
  CREATE_ALERT = '@@alerts/CREATE_ALERT',
  CREATE_ALERT_SUCCEEDED = '@@alerts/CREATE_ALERT_SUCCEEDED',
  CREATE_ALERT_FAILED = '@@alerts/CREATE_ALERT_FAILED',

  ACTIVATE_ALERT = '@@alerts/ACTIVATE_ALERT',
  ACTIVATE_ALERT_SUCCEEDED = '@@alerts/ACTIVATE_ALERT_SUCCEEDED',
  ACTIVATE_ALERT_FAILED = '@@alerts/ACTIVATE_ALERT_FAILED',

  DEACTIVATE_ALERT = '@@alerts/DEACTIVATE_ALERT',
  DEACTIVATE_ALERT_SUCCEEDED = '@@alerts/DEACTIVATE_ALERT_SUCCEEDED',
  DEACTIVATE_ALERT_FAILED = '@@alerts/DEACTIVATE_ALERT_FAILED',

  DELETE_ALERT = '@@alerts/DELETE_ALERT',
  DELETE_ALERT_SUCCEEDED = '@@alerts/DELETE_ALERT_SUCCEEDED',
  DELETE_ALERT_FAILED = '@@alerts/DELETE_ALERT_FAILED',

  FETCH_ALERT = '@@alerts/FETCH_ALERT',
  FETCH_ALERT_SUCCEEDED = '@@alerts/FETCH_ALERT_SUCCEEDED',
  FETCH_ALERT_FAILED = '@@alerts/FETCH_ALERT_FAILED',

  UPDATE_ALERT = '@@alerts/UPDATE_ALERT',
  UPDATE_ALERT_SUCCEEDED = '@@alerts/UPDATE_ALERT_SUCCEEDED',
  UPDATE_ALERT_FAILED = '@@alerts/UPDATE_ALERT_FAILED',

  RESET_ALERT = '@@alerts/RESET_ALERT',
  RESET_ALERT_SUCCEEDED = '@@alerts/RESET_ALERT_SUCCEEDED',
  RESET_ALERT_FAILED = '@@alerts/RESET_ALERT_FAILED',

  OPENFORM = '@@alerts/OPENFORM',
  CLOSEFORM = '@@alerts/CLOSEFORM',
}

export interface ICreateAlert {
  contactGroupId: string;
  mode: string;
  name: string;
  time?: number;
  topic?: string;
  dtype?: string;
  query?: string;
  queryType?: string;
  type: string;
}
export interface IUpdateAlert extends ICreateAlert {
  id: string;
}
export interface IDeleteAlert {
  id: string;
}
export interface IResetAlert {
  id: string;
}

export const createAlert = createAsyncAction(
  Types.CREATE_ALERT,
  Types.CREATE_ALERT_SUCCEEDED,
  Types.CREATE_ALERT_FAILED
)<ICreateAlert, Alert, void>();

export const deleteAlert = createAsyncAction(
  Types.DELETE_ALERT,
  Types.DELETE_ALERT_SUCCEEDED,
  Types.DELETE_ALERT_FAILED
)<IDeleteAlert, IDeleteAlert, IDeleteAlert>();

export const activateAlert = createAsyncAction(
  Types.ACTIVATE_ALERT,
  Types.ACTIVATE_ALERT_SUCCEEDED,
  Types.ACTIVATE_ALERT_FAILED
)<string, IResetAlert, void>();

export const deactivateAlert = createAsyncAction(
  Types.DEACTIVATE_ALERT,
  Types.DEACTIVATE_ALERT_SUCCEEDED,
  Types.DEACTIVATE_ALERT_FAILED
)<string, IResetAlert, void>();

export const fetchAlerts = createAsyncAction(
  Types.FETCH_ALERT,
  Types.FETCH_ALERT_SUCCEEDED,
  Types.FETCH_ALERT_FAILED
)<void, Alert[], void>();

export const updateAlert = createAsyncAction(
  Types.UPDATE_ALERT,
  Types.UPDATE_ALERT_SUCCEEDED,
  Types.UPDATE_ALERT_FAILED
)<IUpdateAlert, Alert, void>();

export const resetAlert = createAsyncAction(
  Types.RESET_ALERT,
  Types.RESET_ALERT_SUCCEEDED,
  Types.RESET_ALERT_FAILED
)<IResetAlert, Alert, void>();

export const openAlertForm = createAction(
  Types.OPENFORM,
  (data?: any) => data
)();
export const closeAlertForm = createAction(Types.CLOSEFORM)();
