import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';

import Account from '@models/Account';
import LoadingButton from '../utils/LoadingButton';

interface IProps {
  account?: Account;
  loading: boolean;
  router: any;
  logout: () => void;
}

const AuthPage: FunctionComponent<IProps> = ({
  account,
  router,
  loading,
  logout,
}) => {
  if (!account) {
    window.location.href = `${
      process.env.REACT_APP_DASHBOARD_URL
    }/en/login?redirect=${encodeURIComponent(
      process.env.REACT_APP_PUBLICPATH as string
    )}`;
    return null;
  }

  if (account.admin) {
    return router.navitate('/');
  }

  return (
    <Grid container justifyContent="center" style={{ zIndex: 1 }}>
      <Grid item sm={4}>
        <Box mt={12} ml={2} mr={2} mb={2}>
          <Card style={{ width: '100%' }}>
            <CardHeader title="Authentication" />
            {account && !account.admin && (
              <CardContent>
                <InputLabel>Non admin account login detected</InputLabel>
              </CardContent>
            )}
            <CardActions style={{ justifyContent: 'flex-end' }}>
              {account && !account.admin && (
                <LoadingButton
                  onClick={logout}
                  variant="contained"
                  color="primary"
                  loading={loading}
                >
                  Log out
                </LoadingButton>
              )}
            </CardActions>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};
AuthPage.defaultProps = {
  account: undefined,
};

export default AuthPage;
