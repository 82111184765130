import Axios from 'axios';

import { clearLogin } from '@actions/index';
import store from '../store';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Cache-Control': 'no-cache' },
  timeout: 120000,
  withCredentials: true,
});

axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response.status === 401) {
      store.dispatch(clearLogin());
    }

    return Promise.reject(error);
  }
);

export const apiAxios = Axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_API_URL,
  headers: { 'Cache-Control': 'no-cache' },
  timeout: 120000,
  withCredentials: true,
});

apiAxios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response.status === 401) {
      store.dispatch(clearLogin());
    }

    return Promise.reject(error);
  }
);

export default axios;
