import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import DvrIcon from '@mui/icons-material/Dvr';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GamesIcon from '@mui/icons-material/Games';
import TodayIcon from '@mui/icons-material/Today';
import MemoryIcon from '@mui/icons-material/Memory';
import PollIcon from '@mui/icons-material/Poll';
import LaunchIcon from '@mui/icons-material/Launch';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SettingsIcon from '@mui/icons-material/SettingsSharp';
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';
import StorageIcon from '@mui/icons-material/Storage';
import StoreIcon from '@mui/icons-material/Store';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WarningIcon from '@mui/icons-material/Warning';
import clsx from 'clsx';
import React, {
  FunctionComponent,
  Fragment,
  ReactElement,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { PRIMARY_COLOR } from '../utils/colors';

export enum TabType {
  CUSTOMERS = 'customers',
  SYSTEM = 'system',
  UTILS = 'utils',
}

export interface ITab {
  id: string;
  exact?: boolean;
  shortcutColor?: string;
  icon: ReactElement<any>;
  link?: string;
  title: string;
  type: TabType;
  subTabs?: ITab[];
}

export const tabs: ITab[] = [
  {
    id: 'organisations',
    icon: <StoreIcon />,
    link: '/organisations',
    title: 'Organisations',
    type: TabType.CUSTOMERS,
  },
  {
    id: 'accounts',
    icon: <SupervisorAccountIcon />,
    link: '/accounts',
    title: 'Accounts',
    type: TabType.CUSTOMERS,
  },
  {
    id: 'tickets',
    shortcutColor: PRIMARY_COLOR,
    icon: <ContactSupportIcon />,
    link: '/tickets',
    title: 'Tickets',
    type: TabType.CUSTOMERS,
  },
  {
    id: 'kafka',
    title: 'Kafka',
    icon: <SettingsInputAntennaIcon />,
    type: TabType.SYSTEM,
    subTabs: [
      {
        id: 'streams',
        icon: <ViewStreamIcon />,
        title: 'Streams',
        link: '/kafka/streams',
        type: TabType.SYSTEM,
      },
      {
        id: 'offsets',
        icon: <GamesIcon />,
        title: 'Offsets',
        link: '/kafka/offsets',
        type: TabType.SYSTEM,
      },
      {
        id: 'groups',
        icon: <DvrIcon />,
        title: 'Groups',
        link: '/kafka/groups',
        type: TabType.SYSTEM,
      },
    ],
  },
  {
    id: 'loadtests',
    icon: <MemoryIcon />,
    title: 'Load Tests',
    link: '/tests/load',
    type: TabType.SYSTEM,
  },
  {
    id: 'dumpdata',
    icon: <StorageIcon />,
    title: 'Dump Data',
    link: '/data/dump',
    type: TabType.SYSTEM,
  },
  {
    id: 'infrastructure',
    icon: <StorageIcon />,
    title: 'Infrastructure',
    link: '/system/infrastructure',
    type: TabType.SYSTEM,
  },
  {
    id: 'status',
    icon: <PollIcon />,
    title: 'Status',
    link: '/system/status',
    type: TabType.SYSTEM,
  },
  {
    id: 'alerts',
    icon: <WarningIcon />,
    title: 'Alerts',
    link: '/system/alerts',
    type: TabType.SYSTEM,
  },
  {
    id: 'gateways',
    icon: <SpeakerPhoneIcon />,
    title: 'Gateways',
    link: '/system/endpoints',
    type: TabType.SYSTEM,
  },
  {
    id: 'software',
    icon: <CloudDownloadIcon />,
    title: 'Software',
    link: '/software',
    type: TabType.UTILS,
  },
  {
    id: 'calendar',
    icon: <TodayIcon />,
    title: 'Calendar',
    link: '/calendar',
    type: TabType.UTILS,
  },
  {
    id: 'settings',
    icon: <SettingsIcon />,
    title: 'Settings',
    link: '/settings',
    type: TabType.UTILS,
  },
];

interface IProps {
  open: boolean;
  shortcuts: Record<string, boolean>;
  theme: 'light' | 'dark';
  toggleMenu: (open: boolean) => void;
  updateShortcut: (id: string) => void;
}

const Menu: FunctionComponent<IProps> = ({
  open,
  shortcuts,
  toggleMenu,
  theme,
  updateShortcut,
}: IProps) => {
  const state: Record<string, boolean> = {};
  const [menuState, setMenuState] = useState(state);

  let dashboardURL = 'https://dashboard.logidot.com';
  if (window.location.origin.indexOf('localhost') !== -1) {
    dashboardURL = 'http://localhost:3000';
  } else if (window.location.origin.indexOf('console.dev') !== -1) {
    dashboardURL = 'https://dashboard.dev.sonodot.com';
  }

  const handleShortcutClick = (event: any, id: string) => {
    event.preventDefault();
    event.stopPropagation();

    updateShortcut(id);
  };

  const getTabs = (type: string) =>
    tabs
      .filter((tab) => tab.type === type)
      .map((tab) => {
        const newMenuState = {
          ...menuState,
          [tab.title]: !menuState[tab.title],
        };

        return (
          <Fragment key={tab.id}>
            <ListItem
              button
              component={tab.link ? Link : 'span'}
              to={tab.link ? tab.link : undefined}
              onClick={() =>
                tab.link ? toggleMenu(false) : setMenuState(newMenuState)
              }
            >
              <ListItemIcon className="menu-icon-container">
                <>
                  {tab.icon}
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    className="menu-shortcut-btn"
                    style={{
                      color: shortcuts[tab.id] ? PRIMARY_COLOR : undefined,
                    }}
                    onClick={(event: any) => handleShortcutClick(event, tab.id)}
                  >
                    <LaunchIcon style={{ fontSize: '0.75rem' }} />
                  </IconButton>
                </>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {tab.title}
                  </Typography>
                }
              />
              {(tab.subTabs || []).length > 0 && (
                <>{menuState[tab.title] ? <ExpandLess /> : <ExpandMore />}</>
              )}
            </ListItem>
            {(tab.subTabs || []).length > 0 && (
              <Collapse in={menuState[tab.title]} timeout="auto" unmountOnExit>
                <Box pl={2} pr={2}>
                  <List component="div">
                    {(tab.subTabs || []).map((sTab) => (
                      <ListItem
                        key={sTab.id}
                        button
                        component={Link}
                        to={sTab.link!}
                        onClick={() => toggleMenu(false)}
                      >
                        <ListItemIcon>{sTab.icon}</ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle1"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {sTab.title}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Collapse>
            )}
          </Fragment>
        );
      });

  const footerClass = clsx('menu-footer', {
    dark: theme === 'dark',
  });

  return (
    <Paper
      onMouseLeave={() => toggleMenu(false)}
      className="menu"
      style={{ display: open ? 'block' : 'none' }}
    >
      <IconButton className="menu-close" onClick={() => toggleMenu(false)}>
        <CloseIcon />
      </IconButton>
      <Grid container>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={<ListSubheader component="div">Customers</ListSubheader>}
          >
            {getTabs('customers')}
          </List>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={<ListSubheader component="div">System</ListSubheader>}
          >
            {getTabs('system')}
          </List>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={<ListSubheader component="div">Utils</ListSubheader>}
          >
            {getTabs('utils')}
            <ListItem
              button
              component="a"
              href={dashboardURL}
              onClick={() => toggleMenu(false)}
            >
              <ListItemIcon style={{ marginRight: 0 }}>
                <DesktopMacIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Dashboard
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <div className={footerClass}>
        <Typography variant="caption">
          <span>Copyright © Sonodot Ltd </span>
          {new Date().getFullYear()}
        </Typography>
      </div>
    </Paper>
  );
};

export default Menu;
