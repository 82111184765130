import ContactGroup from '@models/ContactGroup';
import { IContact } from '../components/forms/ContactGroupForm/ContactGroupForm';
import axios from './axios';

export async function createContactGroup(
  name: string,
  contacts: IContact[]
): Promise<ContactGroup> {
  const { data } = await axios.post('/contactgroups', { name, contacts });

  return data;
}

export async function updateContactGroup(
  id: string,
  name: string,
  contacts: IContact[]
): Promise<ContactGroup> {
  const { data } = await axios.put('/contactgroups', { id, name, contacts });

  return data;
}

export async function deleteContactGroup(id: string): Promise<void> {
  await axios.delete(`/contactgroups/id/${id}`);
}

export async function fetchContactGroups(): Promise<ContactGroup[]> {
  const { data } = await axios.get('/contactgroups');

  return data;
}
