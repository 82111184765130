import { ICreateService, IDeleteService, IUpdateService } from '@actions/index';
import Service, { Services } from '@models/Service';
import ServiceInfo from '@models/ServiceInfo';
import ServiceStatus from '@models/ServiceStatus';
import axios from './axios';

export async function fetchServicesInfo(): Promise<ServiceInfo[]> {
  const { data } = await axios.get('/system/services/info');

  return data;
}
export async function fetchServicesStatus(): Promise<ServiceStatus[]> {
  const { data } = await axios.get('/system/services/status');

  return data;
}

export async function fetchInfrastructure(): Promise<Services> {
  const { data } = await axios.get('/system/infrastructure');

  return data;
}

export async function createService(
  properties: ICreateService
): Promise<Service> {
  const { data } = await axios.post(
    '/system/infrastructure/service/',
    properties
  );

  return data;
}

export async function updateService(
  properties: IUpdateService
): Promise<Service> {
  const { data } = await axios.put(
    '/system/infrastructure/service/',
    properties
  );

  return data;
}

export async function deleteService(properties: IDeleteService): Promise<void> {
  const { id } = properties;

  await axios.delete(`/system/infrastructure/service/id/${id}`);
}
