import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { Component } from 'react';

import { ConfirmDialogActions, IConfirmDialog } from './types';

interface IProps extends IConfirmDialog {
  open: boolean;
  onClose: () => void;
}

class ConfirmDialog extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  public handleCancel() {
    const { onCancelation, onClose } = this.props;

    if (onCancelation) {
      onCancelation();
    }

    onClose();
  }

  public handleConfirm() {
    const { onConfirmation, onClose } = this.props;

    onConfirmation();
    onClose();
  }

  public render() {
    const { confirmType, message, open } = this.props;

    let title;
    if (confirmType === ConfirmDialogActions.ACTION) {
      title = 'Confirm';
    } else if (confirmType === ConfirmDialogActions.DELETE) {
      title = 'Confirm DELETION';
    }

    let button;
    if (confirmType === ConfirmDialogActions.ACTION) {
      button = (
        <Button
          onClick={this.handleConfirm}
          variant="contained"
          color="primary"
        >
          <CheckIcon />
          Confirm
        </Button>
      );
    } else if (confirmType === ConfirmDialogActions.DELETE) {
      button = (
        <Button
          onClick={this.handleConfirm}
          variant="contained"
          color="secondary"
        >
          <DeleteForeverIcon />
          DELETE
        </Button>
      );
    }

    return (
      <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div>{message}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          {button}
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDialog;
