import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/tests';

type Actions = ActionType<typeof types>;

interface ITestType {
  loading: boolean;
}
export interface ITestsState {
  readonly load: ITestType;
}

const initialState: ITestsState = {
  load: {
    loading: false,
  },
};

export default (
  state: ITestsState = initialState,
  action: Actions
): ITestsState => {
  switch (action.type) {
    case getType(types.requestLoadTest.request):
      return {
        ...state,
        load: {
          loading: true,
        },
      };
    case getType(types.requestLoadTest.success):
    case getType(types.requestLoadTest.failure):
      return {
        ...state,
        load: {
          loading: false,
        },
      };

    default:
      return state;
  }
};
