import { createAsyncAction } from 'typesafe-actions';

enum Types {
  REQUEST_LOAD_TEST = '@@tests/REQUEST_LOAD_TEST',
  REQUEST_LOAD_TEST_SUCCEEDED = '@@tests/REQUEST_LOAD_TEST_SUCCEEDED',
  REQUEST_LOAD_TEST_FAILED = '@@tests/REQUEST_LOAD_TEST_FAILED',
}

interface IRequestLoadTest {
  uuid: string;
  stressLevel: number;
}

export const requestLoadTest = createAsyncAction(
  Types.REQUEST_LOAD_TEST,
  Types.REQUEST_LOAD_TEST_SUCCEEDED,
  Types.REQUEST_LOAD_TEST_FAILED
)<IRequestLoadTest, void, void>();

export default {};
