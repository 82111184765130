import { ActionType, getType } from 'typesafe-actions';

import * as auth from '@actions/authentication';
import Account from '@models/Account';
import FetchError from '@models/FetchError';

type AuthenticationAction = ActionType<typeof auth>;

export interface IAuthenticationState {
  readonly account?: Account;
  readonly firstAccountCheck: boolean;
  readonly authenticated: boolean;
  readonly error?: FetchError;
  readonly loading: boolean;
}

const initialState: IAuthenticationState = {
  authenticated: false,
  /** Represents the attempt at authentication with a cookie in storage
   * Being true does not mean the authentication succeeded, just that
   * it got a response from the server.
   */
  firstAccountCheck: false,
  loading: false,
};

export default (
  state: IAuthenticationState = initialState,
  action: AuthenticationAction
): IAuthenticationState => {
  switch (action.type) {
    case getType(auth.fetchAccount.request):
      return {
        ...state,
        loading: true,
      };

    case getType(auth.fetchAccount.success): {
      const account = action.payload;

      return {
        ...state,
        account,
        authenticated: true,
        error: undefined,
        firstAccountCheck: true,
        loading: false,
      };
    }

    case getType(auth.fetchAccount.failure): {
      const { error } = action.payload;

      return {
        ...state,
        account: undefined,
        error,
        firstAccountCheck: true,
        loading: false,
      };
    }

    case getType(auth.logout.request):
      return {
        ...state,
        loading: true,
      };

    case getType(auth.clearLogin):
    case getType(auth.logout.success): {
      return {
        ...state,
        account: undefined,
        authenticated: false,
        error: undefined,
        loading: false,
      };
    }

    case getType(auth.logout.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    default:
      return state;
  }
};

