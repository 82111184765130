import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { dismissNotifications } from '@actions/index';
import { IAppState } from '@reducers/index';
import NotificationsList from './NotificationsList';

const mapStateToProps = (state: IAppState) => ({
  loading: state.notifications.loading || state.notifications.dismissLoading,
  notifications: state.notifications.notifications || [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dismissNotifications: (ids: string[]) =>
    dispatch(dismissNotifications.request(ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
