import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/system';
import { Form } from '@models/Form';
import Service, { Services } from '@models/Service';
import ServiceInfo from '@models/ServiceInfo';
import ServiceStatus from '@models/ServiceStatus';

type Actions = ActionType<typeof types>;

interface ISystemInfo {
  readonly system?: ServiceInfo[];
  readonly loading: boolean;
  readonly error?: boolean;
}
interface ISystemStatus {
  readonly status?: ServiceStatus[];
  readonly loading: boolean;
  readonly error?: boolean;
}

interface IServiceForm extends Form {
  data?: Service;
}
interface IInfrastructure {
  readonly form: IServiceForm;
  readonly error?: boolean;
  readonly loading: boolean;
  readonly services?: Services;
}

export interface ISystemState {
  infrastructure: IInfrastructure;
  systemInfo: ISystemInfo;
  systemStatus: ISystemStatus;
}

const initialState: ISystemState = {
  infrastructure: {
    form: {
      loading: false,
      open: false,
    },
    loading: false,
  },
  systemInfo: {
    loading: false,
  },
  systemStatus: {
    loading: false,
  },
};

export default (
  state: ISystemState = initialState,
  action: Actions
): ISystemState => {
  switch (action.type) {
    case getType(types.fetchServicesInfo.request):
      return {
        ...state,
        systemInfo: {
          ...state.systemInfo,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchServicesInfo.success):
      return {
        ...state,
        systemInfo: {
          ...state.systemInfo,
          loading: false,
          system: action.payload,
        },
      };

    case getType(types.fetchServicesInfo.failure):
      return {
        ...state,
        systemInfo: {
          ...state.systemInfo,
          error: true,
          loading: false,
        },
      };

    case getType(types.fetchServicesStatus.request):
      return {
        ...state,
        systemStatus: {
          ...state.systemStatus,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchServicesStatus.success):
      return {
        ...state,
        systemStatus: {
          ...state.systemStatus,
          loading: false,
          status: action.payload,
        },
      };

    case getType(types.fetchServicesStatus.failure):
      return {
        ...state,
        systemStatus: {
          ...state.systemStatus,
          error: true,
          loading: false,
        },
      };

    case getType(types.fetchInfrastructure.request):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchInfrastructure.success): {
      const services = {} as Services;
      action.payload.forEach((s) => {
        services[s.id || ''] = s;
      });

      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          loading: false,
          services,
        },
      };
    }

    case getType(types.fetchInfrastructure.failure):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          error: true,
          loading: false,
        },
      };

    case getType(types.createService.request):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          form: {
            ...state.infrastructure.form,
            error: undefined,
            loading: true,
          },
        },
      };

    case getType(types.createService.success): {
      const { id } = action.payload;

      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          form: {
            loading: false,
            open: false,
          },
          services: {
            ...state.infrastructure.services,
            [id as string]: action.payload,
          },
        },
      };
    }

    case getType(types.createService.failure):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          form: {
            ...state.infrastructure.form,
            error: true,
            loading: false,
          },
        },
      };

    case getType(types.updateService.request):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          form: {
            ...state.infrastructure.form,
            error: undefined,
            loading: true,
          },
        },
      };

    case getType(types.updateService.success): {
      const { id } = action.payload;

      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          form: {
            loading: false,
            open: false,
          },
          services: {
            ...state.infrastructure.services,
            [id as string]: {
              ...(state.infrastructure.services || {})[id],
              ...action.payload,
            },
          },
        },
      };
    }

    case getType(types.updateService.failure):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          form: {
            ...state.infrastructure.form,
            error: true,
            loading: false,
          },
        },
      };

    case getType(types.deleteService.request):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.deleteService.success): {
      const { id } = action.payload;
      const { services } = state.infrastructure;

      delete (services || {})[id];

      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          loading: false,
          services,
        },
      };
    }

    case getType(types.deleteService.failure):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          error: true,
          loading: false,
        },
      };

    case getType(types.openServiceForm):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          form: {
            ...state.infrastructure.form,
            data: action.payload as Service,
            open: true,
          },
        },
      };

    case getType(types.closeServiceForm):
      return {
        ...state,
        infrastructure: {
          ...state.infrastructure,
          form: {
            ...state.infrastructure.form,
            open: false,
          },
        },
      };

    default:
      return state;
  }
};
