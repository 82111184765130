import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/system';
import {
  createService,
  deleteService,
  fetchInfrastructure,
  fetchServicesInfo,
  fetchServicesStatus,
  updateService,
} from '@api/system';
import Service from '@models/Service';
import ServiceStatus from '@models/ServiceStatus';
import ServiceInfo from '@models/ServiceInfo';

export function* fetchServicesInfoSaga() {
  try {
    const servicesInfo: ServiceInfo[] = yield call(fetchServicesInfo);

    yield put(types.fetchServicesInfo.success(servicesInfo));
  } catch (error) {
    yield put(types.fetchServicesInfo.failure());
  }
}
export function* fetchServicesStatusSaga() {
  try {
    const servicesStatus: ServiceStatus[] = yield call(fetchServicesStatus);

    yield put(types.fetchServicesStatus.success(servicesStatus));
  } catch (error) {
    yield put(types.fetchServicesStatus.failure());
  }
}

export function* fetchInfrastructureSaga() {
  try {
    const infrastructure: Service[] = yield call(fetchInfrastructure);

    yield put(types.fetchInfrastructure.success(infrastructure));
  } catch (error) {
    yield put(types.fetchInfrastructure.failure());
  }
}

export function* createServiceSaga(
  action: ActionType<typeof types.createService.request>
) {
  try {
    const data: Service = yield call(createService, action.payload);

    yield put(types.createService.success(data));
  } catch (error) {
    yield put(types.createService.failure());
  }
}

export function* updateServiceSaga(
  action: ActionType<typeof types.updateService.request>
) {
  try {
    yield call(updateService, action.payload);

    yield put(types.updateService.success(action.payload));
  } catch (error) {
    yield put(types.updateService.failure());
  }
}

export function* deleteServiceSaga(
  action: ActionType<typeof types.deleteService.request>
) {
  try {
    yield call(deleteService, action.payload);

    yield put(types.deleteService.success(action.payload));
  } catch (error) {
    yield put(types.deleteService.failure());
  }
}
