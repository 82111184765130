import { createAsyncAction } from 'typesafe-actions';

import KafkaMeta from '@models/KafkaMeta';
import KafkaOffset from '@models/KafkaOffset';

enum Types {
  FETCH_KAFKA_METADATA = '@@kafka/FETCH_KAFKA_METADATA',
  FETCH_KAFKA_METADATA_SUCCEEDED = '@@kafka/FETCH_KAFKA_METADATA_SUCCEEDED',
  FETCH_KAFKA_METADATA_FAILED = '@@kafka/FETCH_KAFKA_METADATA_FAILED',

  FETCH_KAFKA_TOPIC_OFFSETS = '@@kafka/FETCH_KAFKA_TOPIC_OFFSETS',
  FETCH_KAFKA_TOPIC_OFFSETS_SUCCEEDED = '@@kafka/FETCH_KAFKA_TOPIC_OFFSETS_SUCCEEDED',
  FETCH_KAFKA_TOPIC_OFFSETS_FAILED = '@@kafka/FETCH_KAFKA_TOPIC_OFFSETS_FAILED',
}

export const fetchKafkaMetadata = createAsyncAction(
  Types.FETCH_KAFKA_METADATA,
  Types.FETCH_KAFKA_METADATA_SUCCEEDED,
  Types.FETCH_KAFKA_METADATA_FAILED
)<void, KafkaMeta, void>();

export interface IFetchKafkaTopicOffsets {
  group: string;
}
export const fetchKafkaTopicOffsets = createAsyncAction(
  Types.FETCH_KAFKA_TOPIC_OFFSETS,
  Types.FETCH_KAFKA_TOPIC_OFFSETS_SUCCEEDED,
  Types.FETCH_KAFKA_TOPIC_OFFSETS_FAILED
)<IFetchKafkaTopicOffsets, KafkaOffset[], void>();
