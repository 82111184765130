import { createAsyncAction } from 'typesafe-actions';

import Account from '@models/Accounts';

enum Types {
  ACTIVATE_ACCOUNT = '@@accounts/ACTIVATE_ACCOUNT',
  ACTIVATE_ACCOUNT_SUCCEEDED = '@@accounts/ACTIVATE_ACCOUNT_SUCCEEDED',
  ACTIVATE_ACCOUNT_FAILED = '@@accounts/ACTIVATE_ACCOUNT_FAILED',

  CHANGE_ACCOUNT_ADMIN = '@@accounts/CHANGE_ACCOUNT_ADMIN',
  CHANGE_ACCOUNT_ADMIN_SUCCEEDED = '@@accounts/CHANGE_ACCOUNT_ADMIN_SUCCEEDED',
  CHANGE_ACCOUNT_ADMIN_FAILED = '@@accounts/CHANGE_ACCOUNT_ADMIN_FAILED',

  FETCH_ACCOUNTS = '@@accounts/FETCH_ACCOUNTS',
  FETCH_ACCOUNTS_SUCCEEDED = '@@accounts/FETCH_ACCOUNTS_SUCCEEDED',
  FETCH_ACCOUNTS_FAILED = '@@accounts/FETCH_ACCOUNTS_FAILED',

  UPDATE_ACCOUNT_ORGANISATIONS = '@@accounts/UPDATE_ACCOUNT_ORGANISATIONS',
  UPDATE_ACCOUNT_ORGANISATIONS_SUCCEEDED = '@@accounts/UPDATE_ACCOUNT_ORGANISATIONS_SUCCEEDED',
  UPDATE_ACCOUNT_ORGANISATIONS_FAILED = '@@accounts/UPDATE_ACCOUNT_ORGANISATIONS_FAILED',
}

export interface IActivateAccount {
  id: string;
  active: boolean;
}

export interface IChangeAccountAdmin {
  id: string;
  admin: boolean;
}

export interface IUpdateAccountOrganisations {
  id: string;
  organisations: string[];
}

export const activateAccount = createAsyncAction(
  Types.ACTIVATE_ACCOUNT,
  Types.ACTIVATE_ACCOUNT_SUCCEEDED,
  Types.ACTIVATE_ACCOUNT_FAILED
)<IActivateAccount, IActivateAccount, void>();

export const changeAccountAdmin = createAsyncAction(
  Types.CHANGE_ACCOUNT_ADMIN,
  Types.CHANGE_ACCOUNT_ADMIN_SUCCEEDED,
  Types.CHANGE_ACCOUNT_ADMIN_FAILED
)<IChangeAccountAdmin, IChangeAccountAdmin, void>();

export const fetchAccounts = createAsyncAction(
  Types.FETCH_ACCOUNTS,
  Types.FETCH_ACCOUNTS_SUCCEEDED,
  Types.FETCH_ACCOUNTS_FAILED
)<void, Account[], void>();

export const updateAccountOrganisations = createAsyncAction(
  Types.UPDATE_ACCOUNT_ORGANISATIONS,
  Types.UPDATE_ACCOUNT_ORGANISATIONS_SUCCEEDED,
  Types.UPDATE_ACCOUNT_ORGANISATIONS_FAILED
)<IUpdateAccountOrganisations, IUpdateAccountOrganisations, void>();
