import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Account from '@models/Account';
import Notifications from './Notifications';

interface IProps {
  authenticated: boolean;
  open: boolean;
  loading: boolean;
  // eslint-disable-next-line react/require-default-props
  account?: Account;
  logout: () => void;
  toggleMenu: (open: boolean) => void;
}

interface IState {
  anchorEl?: HTMLElement;
}

class Navbar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: undefined,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  public handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose() {
    this.setState({ anchorEl: undefined });
  }

  public handleLogout() {
    const { logout } = this.props;

    logout();

    this.setState({ anchorEl: undefined });
  }

  public toggleMenu() {
    const { toggleMenu, open } = this.props;

    toggleMenu(!open);
  }

  public render() {
    const { authenticated, loading, account } = this.props;
    const { anchorEl } = this.state;

    return (
      <AppBar
        className="navbar"
        position="static"
        style={{ backgroundColor: '#000' }}
      >
        <Toolbar
          className="toolbar"
          variant="dense"
          style={{ justifyContent: 'space-between' }}
        >
          {authenticated && (account || ({} as Account)).admin && (
            <IconButton
              className="navbar-menu-button"
              color="inherit"
              aria-label="Menu"
              onClick={this.toggleMenu}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link id="app-title" to="/">
            <img
              src={process.env.REACT_APP_ORGANISATION_LOGO}
              alt={`${process.env.REACT_APP_ORGANISATION_NAME} Logo`}
              style={{ maxHeight: '26px', maxWidth: '125px' }}
            />
          </Link>
          {authenticated && (account || ({} as Account)).admin && (
            <div style={{ float: 'right' }}>
              <Notifications />
              <IconButton
                aria-label="More"
                aria-owns={anchorEl ? 'long-menu' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={this.handleClick}
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={this.handleClose}
              >
                <Box p={2} pt={1} pb={1}>
                  {(account || ({} as Account)).name}
                </Box>
                <Divider />
                <MenuList>
                  <MenuItem
                    onClick={this.handleLogout}
                    color="inherit"
                    disabled={loading}
                  >
                    <ListItemIcon>
                      {loading === true ? (
                        <CircularProgress
                          className="loading-button"
                          color="primary"
                          size={24}
                        />
                      ) : (
                        <ExitToAppIcon />
                      )}
                    </ListItemIcon>
                    Log out
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

export default Navbar;
