import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import Loading from './utils/Loading';

const Accounts = lazy(() => import('./tabs/Accounts'));
const DataDump = lazy(() => import('./tabs/Data/Dump'));
const KafkaOffsets = lazy(() => import('./tabs/Kafka/Offsets'));
const KafkaGroups = lazy(() => import('./tabs/Kafka/Groups'));
const KafkaStreams = lazy(() => import('./tabs/Kafka/Streams'));
const Main = lazy(() => import('./tabs/Main'));
const Organisations = lazy(() => import('./tabs/Organisations'));
const Software = lazy(() => import('./tabs/Software'));
const System = lazy(() => import('./tabs/System'));
const Alerts = lazy(() => import('./tabs/System/Alerts'));
const EndPoints = lazy(() => import('./tabs/System/EndPoints'));
const Decawave = lazy(
  () => import('./tabs/System/EndPoints/Gateways/Decawave')
);
const SystemInfrastructure = lazy(() => import('./tabs/System/Infrastructure'));
const SystemStatus = lazy(() => import('./tabs/System/Status'));
const TestsLoad = lazy(() => import('./tabs/Tests/Load'));
const Calendar = lazy(() => import('./tabs/Calendar'));
const Tickets = lazy(() => import('./tabs/Tickets'));
const Settings = lazy(() => import('./tabs/Settings'));

const Tabs: FunctionComponent<{}> = () => (
  <Suspense fallback={<Loading loading />}>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/data/dump" element={<DataDump />} />
      <Route path="/organisations" element={<Organisations />} />
      <Route path="/accounts" element={<Accounts />} />
      <Route path="/kafka/offsets" element={<KafkaOffsets />} />
      <Route path="/kafka/groups" element={<KafkaGroups />} />
      <Route path="/kafka/streams" element={<KafkaStreams />} />
      <Route path="/tests/load" element={<TestsLoad />} />
      <Route path="/software" element={<Software />} />
      <Route path="/system" element={<System />} />
      <Route path="/system/alerts" element={<Alerts />} />
      <Route path="/system/endpoints" element={<EndPoints />} />
      <Route path="/system/endpoints/:page" element={<EndPoints />} />
      <Route path="/system/endpoints/uwb/:ip" element={<Decawave />} />
      <Route path="/system/status" element={<SystemStatus />} />
      <Route
        path="/system/infrastructure"
        element={<SystemInfrastructure />}
      />
      <Route path="/calendar" element={<Calendar />} />
      <Route path="/tickets/*" element={<Tickets />} />
      <Route path="/settings" element={<Settings />} />
      <Route
        path="*"
        element={<Navigate to="/" />}
      />
    </Routes>
  </Suspense>
);
export default Tabs;
