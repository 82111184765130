import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/tests';
import { requestLoadTest } from '@api/tests';

export function* requestLoadTestSaga(
  action: ActionType<typeof types.requestLoadTest.request>
) {
  const { uuid, stressLevel } = action.payload;
  try {
    yield call(requestLoadTest, uuid, stressLevel);

    yield put(types.requestLoadTest.success());
  } catch (error) {
    yield put(types.requestLoadTest.failure());
  }
}

export default {};
