import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/data';
import { fetchDataDump } from '@api/data';

export function* fetchDataDumpSaga(
  action: ActionType<typeof types.fetchDataDump.request>
) {
  try {
    yield call(fetchDataDump, action.payload);

    yield put(types.fetchDataDump.success());
  } catch (error) {
    yield put(types.fetchDataDump.failure());
  }
}

export default {};
