import FileSaver from 'file-saver';

import { IFetchDumpData } from '@actions/index';
import axios from './axios';

export async function fetchDataDump(properties: IFetchDumpData): Promise<void> {
  const res = await axios.post('/data/dump', properties);

  const { query, type, organisationId } = properties;

  const blob = new Blob([res.data], { type: 'text/csv' });
  FileSaver.saveAs(
    blob,
    `${query}-${type || ''}-${organisationId || ''}-Dump.csv`
  );

  return res.data;
}

export default {};
