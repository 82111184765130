import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { dismissNotifications, fetchNotifications } from '@actions/index';
import { IAppState } from '@reducers/index';

import Notifications from './Notifications';

const mapStateToProps = (state: IAppState) => ({
  dismissLoading: state.notifications.dismissLoading,
  loading: state.notifications.loading,
  notifications: state.notifications.notifications || [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dismissNotifications: (ids: string[]) =>
    dispatch(dismissNotifications.request(ids)),
  fetchNotifications: () => dispatch(fetchNotifications.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
