import { createAsyncAction } from 'typesafe-actions';

enum Types {
  FETCH_DATA_DUMP = '@@dump/FETCH_DATA_DUMP',
  FETCH_DATA_DUMP_SUCCEEDED = '@@dump/FETCH_DATA_DUMP_SUCCEEDED',
  FETCH_DATA_DUMP_FAILED = '@@dump/FETCH_DATA_DUMP_FAILED',
}

export interface IFilter {
  key: string;
  op: string;
  value: any;
}
export interface IFetchDumpData {
  query: string;
  type?: string;
  filters: IFilter[];
  organisationId?: string;
}

export const fetchDataDump = createAsyncAction(
  Types.FETCH_DATA_DUMP,
  Types.FETCH_DATA_DUMP_SUCCEEDED,
  Types.FETCH_DATA_DUMP_FAILED
)<IFetchDumpData, void, void>();
