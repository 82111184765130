import Ticket, { TicketComment } from '@models/Ticket';
import axios from './axios';

export async function fetchTickets(ticketStatus: string): Promise<Ticket[]> {
  const { data } = await axios.get(`/tickets/status/${ticketStatus}`);

  return data;
}

export async function archiveTicket(
  organisationId: string,
  id: string
): Promise<Ticket> {
  const { data: ticket } = await axios.put('/tickets/archive', {
    organisationId,
    id,
  });

  return ticket;
}

export async function createTicketComment(
  organisationId: string,
  ticketId: string,
  comment: string
): Promise<TicketComment> {
  const { data: ticket } = await axios.post('/tickets/comments/new', {
    organisationId,
    ticketId,
    comment,
  });

  return ticket;
}

export async function deleteTicketComment(
  organisationId: string,
  ticketId: string,
  id: string
): Promise<void> {
  await axios.put('/tickets/comments/delete', {
    organisationId,
    ticketId,
    id,
  });
}
