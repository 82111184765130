import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/organisations';
import { Form } from '@models/Form';
import { Organisation } from '@models/Organisation';

type Actions = ActionType<typeof types>;

interface IOrganisationForm extends Form {
  data?: Organisation;
}

export interface IOrganisationsState {
  readonly error?: boolean;
  readonly form: IOrganisationForm;
  readonly organisations: Record<string, Organisation>;
  readonly loading: boolean;
}

const initialState: IOrganisationsState = {
  form: {
    loading: false,
    open: false,
  },
  loading: false,
  organisations: {},
};

export default (
  state: IOrganisationsState = initialState,
  action: Actions
): IOrganisationsState => {
  switch (action.type) {
    case getType(types.activateOrganisation.request):
      return {
        ...state,
        loading: true,
      };

    case getType(types.activateOrganisation.success): {
      const { active, id } = action.payload;

      return {
        ...state,
        loading: false,
        organisations: {
          ...state.organisations,
          [id]: {
            ...state.organisations[id],
            active,
          },
        },
      };
    }
    case getType(types.activateOrganisation.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.createOrganisation.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.createOrganisation.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          loading: false,
          open: false,
        },
        organisations: {
          ...state.organisations,
          [id as string]: action.payload,
        },
      };
    }
    case getType(types.createOrganisation.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.fetchOrganisations.request):
      return {
        ...state,
        loading: true,
      };

    case getType(types.fetchOrganisations.success): {
      const organisations = {} as Record<string, Organisation>;
      action.payload.forEach((g) => {
        organisations[g.id] = g;
      });

      return {
        ...state,
        loading: false,
        organisations,
      };
    }

    case getType(types.fetchOrganisations.failure):
      return {
        ...state,
        error: true,
        loading: false,
      };

    case getType(types.updateOrganisation.request):
      return {
        ...state,
        form: {
          ...state.form,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.updateOrganisation.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          loading: false,
          open: false,
        },
        organisations: {
          ...state.organisations,
          [id]: {
            ...state.organisations[id],
            ...action.payload,
          },
        },
      };
    }
    case getType(types.updateOrganisation.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          error,
          loading: false,
        },
      };
    }

    case getType(types.openOrganisationForm): {
      return {
        ...state,
        form: {
          data: action.payload,
          loading: false,
          open: true,
        },
      };
    }

    case getType(types.closeOrganisationForm): {
      return {
        ...state,
        form: {
          ...state.form,
          open: false,
        },
      };
    }

    default:
      return state;
  }
};
