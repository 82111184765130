import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/kafka';
import KafkaMeta from '@models/KafkaMeta';
import KafkaOffset from '@models/KafkaOffset';

type Actions = ActionType<typeof types>;

interface IMetadata {
  readonly loading: boolean;
  readonly error?: boolean;
  readonly data?: KafkaMeta;
}

interface IOffset {
  readonly loading: boolean;
  readonly error?: boolean;
  readonly data?: KafkaOffset[];
}

export interface IKafkaState {
  readonly metadata: IMetadata;
  readonly offsets: IOffset;
}

const initialState: IKafkaState = {
  metadata: {
    loading: false,
  },
  offsets: {
    loading: false,
  },
};

export default (
  state: IKafkaState = initialState,
  action: Actions
): IKafkaState => {
  switch (action.type) {
    case getType(types.fetchKafkaMetadata.request):
      return {
        ...state,
        metadata: {
          ...state.metadata,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchKafkaMetadata.success):
      return {
        ...state,
        metadata: {
          data: action.payload,
          loading: false,
        },
      };

    case getType(types.fetchKafkaMetadata.failure):
      return {
        ...state,
        metadata: {
          ...state.metadata,
          error: true,
          loading: false,
        },
      };

    case getType(types.fetchKafkaTopicOffsets.request):
      return {
        ...state,
        offsets: {
          ...state.offsets,
          error: undefined,
          loading: true,
        },
      };

    case getType(types.fetchKafkaTopicOffsets.success):
      return {
        ...state,
        offsets: {
          data: action.payload,
          loading: false,
        },
      };

    case getType(types.fetchKafkaTopicOffsets.failure):
      return {
        ...state,
        offsets: {
          ...state.offsets,
          error: true,
          loading: false,
        },
      };

    default:
      return state;
  }
};
