import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '@actions/organisations';
import {
  activateOrganisation,
  createOrganisation,
  fetchOrganisations,
  updateOrganisation,
} from '@api/organisations';
import { treatFetchError } from '@api/utils';
import { Organisation, Organisations } from '@models/Organisation';

export function* activateOrganisationSaga(
  action: ActionType<typeof types.activateOrganisation.request>
) {
  const { active, id } = action.payload;

  try {
    yield call(activateOrganisation, id, active);

    yield put(types.activateOrganisation.success({ active, id }));
  } catch (error) {
    yield put(types.activateOrganisation.failure());
  }
}

export function* createOrganisationSaga(
  action: ActionType<typeof types.createOrganisation.request>
) {
  try {
    const data: Organisation = yield call(createOrganisation, action.payload);

    yield put(types.createOrganisation.success(data));
  } catch (error: any) {
    yield put(
      types.createOrganisation.failure({ error: treatFetchError(error) })
    );
  }
}

export function* fetchOrganisationsSaga() {
  try {
    const organisations: Organisations = yield call(fetchOrganisations);

    yield put(types.fetchOrganisations.success(organisations));
  } catch (error) {
    yield put(types.fetchOrganisations.failure());
  }
}

export function* updateOrganisationSaga(
  action: ActionType<typeof types.updateOrganisation.request>
) {
  try {
    yield call(updateOrganisation, action.payload);

    yield put(types.updateOrganisation.success(action.payload));
  } catch (error: any) {
    yield put(
      types.updateOrganisation.failure({ error: treatFetchError(error) })
    );
  }
}
