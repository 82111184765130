import { createAction, createAsyncAction } from 'typesafe-actions';

import Account from '@models/Account';
import { IFailed } from './types';

enum Types {
  ACCOUNT = '@@authentication/ACCOUNT',
  ACCOUNT_FAILED = '@@authentication/ACCOUNT_FAILED',
  ACCOUNT_SUCCEEDED = '@@authentication/ACCOUNT_SUCCEEDED',

  CLEAR_LOGIN = '@@authentication/CLEAR_LOGIN',

  LOGOUT = '@@authentication/LOGOUT',
  LOGOUT_FAILED = '@@authentication/LOGOUT_FAILED',
  LOGOUT_SUCCEEDED = '@@authentication/LOGOUT_SUCCEEDED',
}

export const fetchAccount = createAsyncAction(
  Types.ACCOUNT,
  Types.ACCOUNT_SUCCEEDED,
  Types.ACCOUNT_FAILED
)<void, Account, IFailed>();

export const logout = createAsyncAction(
  Types.LOGOUT,
  Types.LOGOUT_SUCCEEDED,
  Types.LOGOUT_FAILED
)<void, void, IFailed>();

export const clearLogin = createAction(Types.CLEAR_LOGIN)();
